import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AWS from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
import Hashids from "hashids";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { v4 as uuid } from "uuid";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { DHTML_StoreContext } from "../../store/DHTML_Store.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";
import { PublishMenuStoreContext } from "../../store/Publish_Menu.js";
import { RemixMenuDesktopStoreContext } from "../../store/Remix_Menu_Desktop.js";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import { Config } from "./config/Config";

let followList = [];
let followListCount = 0;
let called = false;
let lastIndexFlow = null;
let followers = [];
let firestore = firebase.firestore();
let currentUserString = localStorage.getItem("flowroom-user");
let incNum = 0;

let options = ["Post", "Update"];
let alreadyDHTML = false;
let arrayLikeObj = {};
let arr = [];
let remixedByArr = [];
let isUpdate = false;

AWS.config.update({
  region: "us-west-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-west-2:5df2511a-5595-416c-b148-aba28893c3f3",
  }),
});

const s3 = new S3();
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 100 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    zIndex: 10000000,
  };
}

const remove_duplicates_es = (arr) => {
  let s = new Set(arr);
  let it = s.values();
  return Array.from(it);
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1000000,
  },
}));

const history = createBrowserHistory();

const Publish_Save = observer((props) => {
  const [openT, setState] = useState(false);

  const toast = (newState) => () => {
    setState(true);
  };

  const closeToast = () => {
    setState(false);
  };

  console.log("save props", props);

  let parts = window.location.pathname.split("/");
  // let parts = window.location.pathname.split("/");
  let lastSegment = parts.pop() || parts.pop();

  function setGetCodeFromIframeAndSaveRoom() {
    return new Promise((resolve) => {
      // WidgetStore.setGetCodeFromIframe(true);

      setTimeout(() => {
        resolve();
      }, 1000); // Wait for 1 second before resolving the Promise
    }).then(() => {
      saveRoom();
      // iframe.contentWindow.location.reload();
    });
  }

  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);

  const DHTML_Store = useContext(DHTML_StoreContext);
  const Remix_Menu_Desktop = useContext(RemixMenuDesktopStoreContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [postSelectedIndex, setPostSelectedIndex] = React.useState(0);
  const [isPostOptionsLoaded, setIsPostOptionsLoaded] = React.useState(0);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [emptyDHTML, setEmptyDHTML] = useState(false);
  const [myFollowerList, setMyFollowerList] = useState([]);
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [disableSend, setDisableSend] = useState(false);

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  if (FlowStore.isFlowOwner) {
    options = ["Post, Save & Share", "Update & Share"];
  } else if (lastSegment === "new") {
    options = ["Post, Save & Share"];
  } else {
    options = ["Post Save & Share Remix"];
  }
  const reviewPostByText = () => {
    // alert(EditorStore.description);
    // alert(EditorStore.title);

    saveRoom();
  };
  let createRemix = (flowId) => {
    if (FlowStore.isFlowOwner) {
      // List of properties you want to check
      const properties = [
        "flowIsMonetized",
        "flowPrice",
        "flowIsApproved",
        "flowFreeTrial",
        "paidRemix",
        "flowPreview",
        "flowThumbnail",
        "flowPerMonth",
        "flowPerYear",
        "flowDays",
        "flowNumberOfUses",
        "flowPreviewId",
        "loadThumbnail",
        "isRemixable",
        "loadThumbnailPaid",
        "isRemixableByEveryone",
        "isRemixableByDevOnly",
        "isRemixableByMeOnly",
        "aiModifyRemix",
        "editorRemix",
        "specificRemixing",
        "remixPrice",
      ];

      // Iterate through each property and create a list of properties that have changed
      const changedProperties = properties.filter(
        (prop) => EditorStore[prop] !== EditorStore[`${prop}Initial`]
      );

      // Check if any property has changed
      const hasChanged = changedProperties.length > 0;

      if (hasChanged) {
        // Log the changed properties
        console.log("Changed properties:", changedProperties);

        if (
          EditorStore.flowIsMonetized == false &&
          EditorStore.flowIsMonetizedInitial == false
        ) {
          EditorStore.setFlowIsApproved(true);
        } else {
          EditorStore.setFlowIsApproved(false);
        }
      }
    } else {
      if (EditorStore.flowIsMonetized == false) {
        EditorStore.setFlowIsApproved(true);
      } else {
        EditorStore.setFlowIsApproved(false);
      }
    }

    let roomDataForRemix = {
      date: new Date(),
      description: PublishMenuStore.description,
      flowId: flowId,
      thumbnail: EditorStore.flowThumbnailImage,
      animation: [],
      title: PublishMenuStore.title,
      urlHTML: DHTML_Store.html,
      urlCSS: DHTML_Store.css,
      urlJS: DHTML_Store.js,
      urlPhoto: currentUser.photoURL,
      userId: currentUser.uid,
      username: currentUser.displayName,
      visibility:
        EditorStore.flowIsApproved === false
          ? "approval"
          : PublishMenuStore.visibility,
      modelId: FlowStore.modelId,
      libraries: PublishMenuStore.libraries,
      cssLibraries: PublishMenuStore.cssLibraries,
      isRemixable: PublishMenuStore.isRemixable,
      AITextPopulated: PublishMenuStore.AITextPopulated,
      Contributors: PublishMenuStore.Contributors,
      widgets: WidgetStore.widgets,
      imageIndexes: WidgetStore.imageIndexes,
      textInputIndexes: WidgetStore.textInputIndexes,
      colorSelectIndexes: WidgetStore.colorSelectIndexes,
      sliderIndexSelect: WidgetStore.sliderIndexes,
      switchIndexSelect: WidgetStore.switchIndexes,
      selectIndexSelect: WidgetStore.selectIndexes,
      checkboxIndexSelect: WidgetStore.checkboxIndexes,
      calendarIndexSelect: WidgetStore.calendarIndexes,
      imageUploaderCount: WidgetStore.imageUploaderCount,
      editorLocked: EditorStore.editorLocked,
      editorLockedPassword: EditorStore.editorLockedPassword,
      editorLockedSelected: EditorStore.editorLockedSelected,
      disableComments: EditorStore.disableComments,
      isAR: EditorStore.isAR,
      isVR: EditorStore.isVR,
      isLive: EditorStore.isLive,
      isAI: EditorStore.isAI,
      hashtags: [],
      hasRemixableAI: WidgetStore.hasRemixableAI,
      followers: myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
      flowRemixer: PublishMenuStore.flowRemixer,
      flowIdOP: lastSegment,
      collaborators: PublishMenuStore.collaborators,
      isAllDevice: EditorStore.isAllDevice,
      deviceMobile: EditorStore.deviceMobile,
      deviceTablet: EditorStore.deviceTablet,
      deviceDesktop: EditorStore.deviceDesktop,
      paymentOneTimeFee: EditorStore.paymentOneTimeFee,
      paymentSubscription: EditorStore.paymentSubscription,
      paymentFreeTrial: EditorStore.paymentFreeTrial,
      isFeatured: EditorStore.isFeatured,
      remixedBy: currentUser.displayName,
      isRemixed: true,
      isAReShare: false,
      isPinned: false,
      isPaidPromotion: false,
      isReplyInComments: false,
      isPinnedBy: firebase.auth().currentUser.displayName,
      remixedCount: 0,
      isNSFW: EditorStore.isNSFW,
      enableGifRecap: EditorStore.enableGifRecap,
      enableAIGifRecap: EditorStore.enableAIGifRecap,
      enableSpecialShare: EditorStore.enableSpecialShare,
      screencap: EditorStore.screencap,
      browserTags: EditorStore.browserTags,
      paymentPlan: EditorStore.paymentPlan,
      isSafari: EditorStore.isSafari,
      isChrome: EditorStore.isChrome,
      isFirefox: EditorStore.isFirefox,
      isOpera: EditorStore.isOpera,
      isVivaldi: EditorStore.isVivaldi,
      isBrave: EditorStore.isBrave,
      deductCreditFrom: {
        uid: firebase.auth().currentUser.uid,
        numberOfTimes:
          FlowStore.shareWithOwnCredits == true ? FlowStore.numberOfTimes : 0,
      },
      uid: firebase.auth().currentUser.uid,
      loadInFeed: EditorStore.loadInFeed,
      hasAudio: EditorStore.hasAudio,
      isRemixableByDevOnly: EditorStore.isRemixableByDevOnly,
      uid: firebase.auth().currentUser.uid,
      flowData: EditorStore.flowData,
      flowIsMonetized: EditorStore.flowIsMonetized,
      flowIsMonetizedInitial: EditorStore.flowIsMonetized,
      flowPrice: EditorStore.flowPrice,
      flowPriceInitial: EditorStore.flowPrice,
      flowIsApproved: EditorStore.flowIsApproved,
      flowIsApprovedInitial: EditorStore.flowIsApproved,
      flowFreeTrial: EditorStore.flowFreeTrial,
      flowFreeTrialInitial: EditorStore.flowFreeTrial,
      flowMonthly: EditorStore.flowMonthly,
      flowMonthlyInitial: EditorStore.flowMonthly,
      flowAnnually: EditorStore.flowAnnually,
      flowAnnuallyInitial: EditorStore.flowAnnually,
      paidRemix: EditorStore.paidRemix,
      paidRemixInitial: EditorStore.paidRemix,
      flowPreview: EditorStore.flowPreview,
      flowPreviewInitial: EditorStore.flowPreview,
      flowThumbnail: EditorStore.flowThumbnail,
      flowThumbnailInitial: EditorStore.flowThumbnail,
      flowPerMonth: EditorStore.flowPerMonth,
      flowPerMonthInitial: EditorStore.flowPerMonth,
      flowPerYear: EditorStore.flowPerYear,
      flowPerYearInitial: EditorStore.flowPerYear,
      monetized: EditorStore.monetized,
      monetizedInitial: EditorStore.monetizedl,
      flowDays: EditorStore.flowDays,
      flowDaysInitial: EditorStore.flowDays,
      flowNumberOfUses: EditorStore.flowNumberOfUses,
      flowNumberOfUsesInitial: EditorStore.flowNumberOfUses,
      flowPreviewId: EditorStore.flowPreviewId,
      flowPreviewIdInitial: EditorStore.flowPreviewId,
      loadThumbnail: EditorStore.loadThumbnail,
      loadThumbnailInitial: EditorStore.loadThumbnail,
      isRemixable: EditorStore.isRemixable,
      isRemixableInitial: EditorStore.isRemixable,
      loadThumbnailPaid: EditorStore.loadThumbnailPaid,
      loadThumbnailPaidInitial: EditorStore.loadThumbnailPaid,
      isRemixableByEveryone: EditorStore.isRemixableByEveryone,
      isRemixableByEveryoneInitial: EditorStore.isRemixableByEveryone,
      isRemixableByDevOnly: EditorStore.isRemixableByDevOnly,
      isRemixableByDevOnlyInitial: EditorStore.isRemixableByDevOnly,
      isRemixableByMeOnly: EditorStore.isRemixableByMeOnly,
      isRemixableByMeOnlyInitial: EditorStore.isRemixableByMeOnlyInitial,

      aiModifyRemix: EditorStore.aiModifyRemix,
      aiModifyRemixInitial: EditorStore.aiModifyRemix,
      editorRemix: EditorStore.editorRemix,
      editorRemixInitial: EditorStore.editorRemix,
      specificRemixing: EditorStore.specificRemixing,
      specificRemixingInitial: EditorStore.specificRemixing,
      remixPrice: EditorStore.remixPrice,
      remixPriceInitial: EditorStore.remixPrice,
      yourRole: EditorStore.yourRole,
    };
    let roomObject = JSON.parse(JSON.stringify(roomDataForRemix));
    //If you're not the owner, you're remixing so first save the post as a remix
    //then call createRoom
    PublishMenuStore.setFlowIdOP(lastSegment);
    firebase
      .firestore()
      .collection("remixes")
      .doc(flowId)
      .set(roomObject, { merge: true })
      .then(() => {
        firebase
          .firestore()
          .collection("flows")
          .doc(PublishMenuStore.flowIdOP)
          .set(
            {
              remixedCount: firebase.firestore.FieldValue.increment(1),
            },
            { merge: true }
          )
          .then(() => {
            const contentFilterModule = (options, prompt) => {
              return fetch(
                `https://us-central1-flowroom-fd862.cloudfunctions.net/contentFilter`,
                {
                  method: `POST`,
                  body: JSON.stringify({
                    options: options,
                    prompt: prompt,
                  }),
                },
                {
                  mode: "cors",
                }
              );
            };
          });
      });
  };

  const contentFilterModule = (options, prompt) => {
    return fetch(
      `https://us-central1-flowroom-fd862.cloudfunctions.net/contentFilter`,
      {
        method: `POST`,
        body: JSON.stringify({ options: options, prompt: prompt }),
      },
      {
        mode: "cors",
      }
    );
  };
  const contentFilter = () => {
    let arr = [];

    WidgetStore.widgets.map((widget) => {
      arr.push(widget.label);
      arr.push(widget.value);
    });
    // let check =
    //   arr.join() +
    //   " " +
    //   PublishMenuStore.title +
    //   " " +
    //   PublishMenuStore.description;
    // let prompt = check;
    // let templateObj = {
    //   options: {
    //     engine: "content-filter-alpha",
    //     maxTokens: 1,
    //     temperature: 0,
    //     topP: 1,
    //     presencePenalty: 0,
    //     frequencyPenalty: 0,
    //     logprobs: 10,
    //   },
    //   prompt: prompt,
    // };
    // console.log("check", check);
    handleSubmit();
    // contentFilterModule(templateObj.options, templateObj.prompt)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("check content", data);
    //     if (data.data.choices[0].text === "0") {
    //       WidgetStore.setShowPostModal(false);
    //       // document.getElementById("horizontal-tab-post-tab").style.background =
    //       //   "transparent";
    //       if (document.getElementById("horizontal-tab-post-tab") !== null) {
    //         document.getElementById(
    //           "horizontal-tab-post-tab"
    //         ).style.background = "transparent";
    //       }
    //       if (
    //         DHTML_Store.html === "" &&
    //         DHTML_Store.css === "" &&
    //         DHTML_Store.js === ""
    //       ) {
    //         WidgetStore.setCurrentErrorMessage(
    //           "You must not submit empty editor fields"
    //         );
    //         WidgetStore.setErrorMessage(true);
    //         WidgetStore.setLoadingOneMoment(false);
    //         PublishMenuStore.setShowShare(true);

    //         return;
    //       }

    //       // saveRoom();
    //       // let iframe = document.getElementById("undefined_output_frame");
    //       // WidgetStore.setIsCurrentlyRemixing(false);
    //       // eval(
    //       //   `try { iframe.contentWindow.${WidgetStore.endRemixing}() } catch(e) { console.error(e) }`
    //       // );

    //       setGetCodeFromIframeAndSaveRoom();

    //       WidgetStore.setLoadingOneMoment(false);
    //     } else {
    //       WidgetStore.setLoadingOneMoment(false);

    //       document.getElementById("horizontal-tab-post-tab").style.background =
    //         "transparent";

    //       WidgetStore.setShowPostModal(false);

    //       WidgetStore.setCurrentErrorMessage(
    //         "Inappropriate content detected. Check your widget labels, title, or description for offensive language."
    //       );
    //       WidgetStore.setErrorMessage(true);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log("content error", e);
    //     WidgetStore.setCurrentErrorMessage(
    //       "Sorry, something went wrong. Please try again. If you continue to receive this error, please report this."
    //     );
    //     WidgetStore.setErrorMessage(true);
    //     WidgetStore.setLoadingOneMoment(false);
    //   });
  };
  const getArr = () => {
    // let currentUserString = JSON.parse(
    //   localStorage.getItem("flowroom-user")
    // ).userId;
    var array = PublishMenuStore.collaborators;
    array.map((item) => {
      if (
        item.user.trim() !== "" &&
        item.user !== undefined &&
        item.user !== null
      ) {
        arr.push({ user: item.user, label: item.label });
      }
    });
    var obj = arr.reduce((a, b, i) => {
      return (a[i] = b), a;
    }, {});

    return JSON.stringify(obj);
  };

  useEffect(() => {
    // if (
    //   PublishMenuStore.remixedBy !== undefined &&
    //   PublishMenuStore.remixedBy !== null &&
    //   PublishMenuStore.remixedBy !== "" &&
    //   PublishMenuStore.remixedBy.length !== 0
    // ) {
    //   PublishMenuStore.remixedBy.map((item) => {
    //     if (item !== undefined && item !== null && item !== "") {
    //       remixedByArr.push({ user: item.user, pic: item.pic });
    //       console.log("publish menu store", remixedByArr);
    //     }
    //   });
    // }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [PublishMenuStore.remixedBy]);

  const createRoom = async (img, html, css, js, flowId) => {
    EditorStore.setIsSaving(true);
    alert(currentUser.photoURL);

    // const iframe = document.getElementById("undefined_output_frame");
    // const iframeWindow = iframe.contentWindow;
    // let audio = false;

    // if (iframeWindow.flowroom && iframeWindow.flowroom.hasAudio === true) {
    //   // The hasAudio property is set to true in the iframe
    //   audio = true;
    // } else {
    //   // The hasAudio property is not set to true in the iframe
    //   audio = false;
    // }
    if (FlowStore.isFlowOwner) {
      // List of properties you want to check
      const properties = [
        "flowIsMonetized",
        "flowPrice",
        "flowIsApproved",
        "flowFreeTrial",
        "paidRemix",
        "flowPreview",
        "flowThumbnail",
        "flowPerMonth",
        "flowPerYear",
        "flowDays",
        "flowNumberOfUses",
        "flowPreviewId",
        "loadThumbnail",
        "isRemixable",
        "loadThumbnailPaid",
        "isRemixableByEveryone",
        "isRemixableByDevOnly",
        "isRemixableByMeOnly",
        "aiModifyRemix",
        "editorRemix",
        "specificRemixing",
        "remixPrice",
      ];

      // Iterate through each property and create a list of properties that have changed
      const changedProperties = properties.filter(
        (prop) => EditorStore[prop] !== EditorStore[`${prop}Initial`]
      );

      // Check if any property has changed
      const hasChanged = changedProperties.length > 0;

      if (hasChanged) {
        // Log the changed properties
        console.log("Changed properties:", changedProperties);

        if (
          EditorStore.flowIsMonetized == false &&
          EditorStore.flowIsMonetizedInitial == false
        ) {
          EditorStore.setFlowIsApproved(true);
        } else {
          EditorStore.setFlowIsApproved(false);
        }
      }
    } else {
      if (EditorStore.flowIsMonetized == false) {
        EditorStore.setFlowIsApproved(true);
      } else {
        EditorStore.setFlowIsApproved(false);
      }
    }

    let userId = currentUser.uid;
    let canvasSize = JSON.parse(localStorage.getItem("canvasSize"));
    let canvasWidth =
      canvasSize && canvasSize.canvasWidth ? canvasSize.canvasWidth : 640;
    let canvasHeight =
      canvasSize && canvasSize.canvasHeight ? canvasSize.canvasHeight : 640;
    let parts = window.location.pathname.split("/");
    // let parts = window.location.pathname.split("/");
    let lastSegment = parts.pop() || parts.pop();
    if (lastSegment !== "new") {
      EditorStore.setIsRemixed(true);
    }
    let roomData = {
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      date: new Date(),
      description: PublishMenuStore.description,
      flowId: flowId,
      thumbnail:
        "https://" + Config.HOST_CLOUDFRONT + Config.URL_THUMBNAIL_PROCESSING,
      animation: [],
      title: PublishMenuStore.title,
      urlHTML: html,
      urlCSS: css,
      urlJS: js,
      urlPhoto: currentUser.photoURL,
      userId: userId,
      username: currentUser.displayName,
      visibility:
        EditorStore.flowIsApproved === false
          ? "approval"
          : PublishMenuStore.visibility,
      modelId: FlowStore.modelId,
      libraries: PublishMenuStore.libraries,
      cssLibraries: PublishMenuStore.cssLibraries,
      isRemixable: PublishMenuStore.isRemixable,
      AITextPopulated: PublishMenuStore.AITextPopulated,
      Contributors: PublishMenuStore.Contributors,
      widgets: WidgetStore.widgets,
      imageIndexes: WidgetStore.imageIndexes,
      textInputIndexes: WidgetStore.textInputIndexes,
      colorSelectIndexes: WidgetStore.colorSelectIndexes,
      sliderIndexSelect: WidgetStore.sliderIndexes,
      switchIndexSelect: WidgetStore.switchIndexes,
      selectIndexSelect: WidgetStore.selectIndexes,
      checkboxIndexSelect: WidgetStore.checkboxIndexes,
      calendarIndexSelect: WidgetStore.calendarIndexes,
      imageUploaderCount: WidgetStore.imageUploaderCount,
      editorLocked: EditorStore.editorLocked,
      editorLockedPassword: EditorStore.editorLockedPassword,
      editorLockedSelected: EditorStore.editorLockedSelected,
      disableComments: EditorStore.disableComments,
      isAR: EditorStore.isAR,
      isVR: EditorStore.isVR,
      isLive: EditorStore.isLive,
      isAI: EditorStore.isAI,
      hashtags: [],
      hasRemixableAI: WidgetStore.hasRemixableAI,
      followers: myFollowerList, // deliberately do not have a remixedBy key because I only want it for updates by someone remixing
      flowRemixer: PublishMenuStore.flowRemixer,
      flowIdOP: lastSegment, // PublishMenuStore.flowIdOP,
      collaborators: PublishMenuStore.collaborators,
      isAllDevice: EditorStore.isAllDevice,
      deviceMobile: EditorStore.deviceMobile,
      deviceTablet: EditorStore.deviceTablet,
      deviceDesktop: EditorStore.deviceDesktop,
      paymentOneTimeFee: EditorStore.paymentOneTimeFee,
      paymentSubscription: EditorStore.paymentSubscription,
      paymentFreeTrial: EditorStore.paymentFreeTrial,
      isFeatured: EditorStore.isFeatured,
      isPinnedBy: EditorStore.isPinnedBy,
      isRemixed: EditorStore.isRemixed,
      isAReShare: false,
      isPinned: EditorStore.isPinned,
      isPaidPromotion: EditorStore.isPaidPromotion,
      isReplyInComments: EditorStore.isReplyInComments,
      remixedCount: 0,
      isNSFW: EditorStore.isNSFW,
      enableGifRecap: EditorStore.enableGifRecap,
      enableAIGifRecap: EditorStore.enableAIGifRecap,
      enableSpecialShare: EditorStore.enableSpecialShare,
      screencap: EditorStore.screencap,
      browserTags: EditorStore.browserTags,
      paymentPlan: EditorStore.paymentPlan,
      isSafari: EditorStore.isSafari,
      isChrome: EditorStore.isChrome,
      isFirefox: EditorStore.isFirefox,
      isOpera: EditorStore.isOpera,
      isVivaldi: EditorStore.isVivaldi,
      isBrave: EditorStore.isBrave,
      loadInFeed: EditorStore.loadInFeed,
      hasAudio: false,
      deductCreditFrom: {
        uid: firebase.auth().currentUser.uid,
        numberOfTimes: FlowStore.shareWithOwnCredits == true ? 1 : 0,
      },
      uid: firebase.auth().currentUser.uid,
      flowData: EditorStore.flowData,
      flowIsMonetized: EditorStore.flowIsMonetized,
      flowIsMonetizedInitial: EditorStore.flowIsMonetized,
      flowPrice: EditorStore.flowPrice,
      flowPriceInitial: EditorStore.flowPrice,
      flowIsApproved: EditorStore.flowIsApproved,
      flowIsApprovedInitial: EditorStore.flowIsApproved,
      flowFreeTrial: EditorStore.flowFreeTrial,
      flowFreeTrialInitial: EditorStore.flowFreeTrial,
      flowMonthly: EditorStore.flowMonthly,
      flowMonthlyInitial: EditorStore.flowMonthly,
      flowAnnually: EditorStore.flowAnnually,
      flowAnnuallyInitial: EditorStore.flowAnnually,
      paidRemix: EditorStore.paidRemix,
      paidRemixInitial: EditorStore.paidRemix,
      flowPreview: EditorStore.flowPreview,
      flowPreviewInitial: EditorStore.flowPreview,
      flowThumbnail: EditorStore.flowThumbnail,
      flowThumbnailInitial: EditorStore.flowThumbnail,
      flowPerMonth: EditorStore.flowPerMonth,
      flowPerMonthInitial: EditorStore.flowPerMonth,
      flowPerYear: EditorStore.flowPerYear,
      flowPerYearInitial: EditorStore.flowPerYear,
      monetized: EditorStore.monetized,
      monetizedInitial: EditorStore.monetizedl,
      flowDays: EditorStore.flowDays,
      flowDaysInitial: EditorStore.flowDays,
      flowNumberOfUses: EditorStore.flowNumberOfUses,
      flowNumberOfUsesInitial: EditorStore.flowNumberOfUses,
      flowPreviewId: EditorStore.flowPreviewId,
      flowPreviewIdInitial: EditorStore.flowPreviewId,
      loadThumbnail: EditorStore.loadThumbnail,
      loadThumbnailInitial: EditorStore.loadThumbnail,
      isRemixable: EditorStore.isRemixable,
      isRemixableInitial: EditorStore.isRemixable,
      loadThumbnailPaid: EditorStore.loadThumbnailPaid,
      loadThumbnailPaidInitial: EditorStore.loadThumbnailPaid,
      isRemixableByEveryone: EditorStore.isRemixableByEveryone,
      isRemixableByEveryoneInitial: EditorStore.isRemixableByEveryone,
      isRemixableByDevOnly: EditorStore.isRemixableByDevOnly,
      isRemixableByDevOnlyInitial: EditorStore.isRemixableByDevOnly,
      isRemixableByMeOnly: EditorStore.isRemixableByMeOnly,
      isRemixableByMeOnlyInitial: EditorStore.isRemixableByMeOnlyInitial,

      aiModifyRemix: EditorStore.aiModifyRemix,
      aiModifyRemixInitial: EditorStore.aiModifyRemix,
      editorRemix: EditorStore.editorRemix,
      editorRemixInitial: EditorStore.editorRemix,
      specificRemixing: EditorStore.specificRemixing,
      specificRemixingInitial: EditorStore.specificRemixing,
      remixPrice: EditorStore.remixPrice,
      remixPriceInitial: EditorStore.remixPrice,
      yourRole: EditorStore.yourRole,
    };

    try {
      await PublishMenuStore.startCreateRoom(roomData, currentUser);
    } catch (error) {
      console.error("Error while creating screenshot:", error);
      // Optionally, you can use an alert or any other way to inform the user
      // alert("Failed to create screenshot.");
    }
    // createSearch();
    try {
      await createScreenShot(flowId);
    } catch (error) {
      console.error("Error while creating screenshot:", error);
      // Optionally, you can use an alert or any other way to inform the user
      // alert("Failed to create screenshot.");
    }
    // createGifAnimation(flowId);
    FlowStore.setFlowId(flowId);
    FlowStore.setRoom(roomData);
    FlowStore.setIsFlowOwner(true);

    if (lastSegment !== "new") {
      createRemix(flowId);
    }
    setDisableSend(false);
    EditorStore.setIsSaving(false);
  };

  const createScreenShot = async (flowId) => {
    try {
      const url = `https://us-central1-flowroom-fd862.cloudfunctions.net/screen?username=${
        firebase.auth().currentUser.displayName
      }&url=https://${
        Config.HOST_CLOUDFRONT
      }/screenshot/?flow=${flowId}&screencap=${
        EditorStore.screencap
      }&gifRecap=${EditorStore.gifRecap}&gifRecapTime=${
        EditorStore.gifRecapTime
      }&gifRecapFunction=${EditorStore.gifRecapFunction}&gifRecapAI=${
        EditorStore.gifRecapAI
      }&gifRecapAIFunctionBeforeRemixModal=${
        EditorStore.gifRecapAIFunctionBeforeRemixModal
      }&gifRecapAITimeBeforeRemixModal=${
        EditorStore.gifRecapAITimeBeforeRemixModal
      }&gifRecapAIFunctionAfterRemixModal=${
        EditorStore.gifRecapAIFunctionAfterRemixModal
      }&gifRecapAITimeAfterRemixModal=${
        EditorStore.gifRecapAITimeAfterRemixModal
      }&flowid=${flowId}&`;

      const response = await fetch(url, { mode: "cors" });
      console.log("[Publish_Save] createScreenShot response:", response);
    } catch (error) {
      // alert(error);
      console.log("[Publish_Save] createScreenShot error:", error);
    }
  };

  const handleSubmit = async () => {
    // if (!recaptchaValue) {
    //   PublishMenuStore.setPostErrorMessage(
    //     "Please complete the CAPTCHA challenge."
    //   );
    //   console.error("Please complete the CAPTCHA challenge.");
    //   return;
    // }

    // try {
    //   const response = await fetch(
    //     "https://us-central1-flowroom-fd862.cloudfunctions.net/checkRecaptcha",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ recaptchaValue }),
    //     }
    //   );

    // const data = await response.json();

    // if (data.success) {
    //   console.log("CAPTCHA verified!");

    // Handle the rest of your form submission logic.
    // saveRoom();
    reviewPostByText();
    // } else {
    //   console.error("CAPTCHA verification failed:", data.message);
    //   PublishMenuStore.setPostErrorMessage(
    //     "CAPTCHA verification failed:",
    //     data.message
    //   );
    //   return;
    // }
    // } catch (error) {
    //   console.error("Error calling Cloud Function:", error);
    //   return;
    // }
  };

  function checkForErrors() {
    const store = EditorStore; // Using shorthand for brevity

    // Check for remix options

    if (store.flowIsMonetized) {
      if (!store.flowPrice || store.flowPrice === 0) {
        PublishMenuStore.setPostErrorMessage(
          "If you've selected to monetize, there should be a price greater than 0."
        );
        return true; // indicates an error was found
      }

      console.log(
        "flowMonthly value:",
        store.flowPerMonth,
        "Type:",
        typeof store.flowPerMonth
      );
      console.log(
        "flowPerYear value:",
        store.flowPerYear,
        "Type:",
        typeof store.flowPerYear
      );
      console.log(
        "flowFreeTrial value:",
        store.flowFreeTrial,
        "Type:",
        typeof store.flowFreeTrial
      );

      if (!store.flowPerMonth && !store.flowPerYear && !store.flowFreeTrial) {
        PublishMenuStore.setPostErrorMessage(
          "You must select free trial, monthly or yearly for your monetized flow."
        );
        return true;
      }

      if (!store.flowThumbnail && !store.flowPreview) {
        PublishMenuStore.setPostErrorMessage(
          "If monetized, you must select either a thumbnail or preview."
        );
        return true;
      }

      if (store.flowPreview && !store.flowPreviewId) {
        PublishMenuStore.setPostErrorMessage(
          "If preview is selected, a flow ID must be provided."
        );
        return true;
      }
    }

    if (store.flowFreeTrial) {
      if (!store.flowDays || store.flowDays === 0) {
        PublishMenuStore.setPostErrorMessage(
          "If free trial is selected, days cannot be empty or 0."
        );
        return true;
      }
      if (!store.flowNumberOfUses || store.flowNumberOfUses === 0) {
        PublishMenuStore.setPostErrorMessage(
          "If free trial is selected, number of uses cannot be empty or 0."
        );
        return true;
      }
    }

    if (store.flowPerMonth && (!store.flowPrice || store.flowPrice === 0)) {
      PublishMenuStore.setPostErrorMessage(
        "If 'per month' is selected, a price should be added."
      );
      return true;
    }

    if (store.paidRemix && (!store.flowPrice || store.flowPrice === 0)) {
      PublishMenuStore.setPostErrorMessage(
        "If user pays to remix this flow, a price should be added."
      );
      return true;
    }

    if (
      store.aiModifyRemix == false &&
      store.editorRemix == false &&
      store.specificRemixing == false
    ) {
      PublishMenuStore.setPostErrorMessage("You must select a remix option.");
      return true;
    }

    // New condition for remixPrice when paidRemix is selected
    if (store.paidRemix && (!store.remixPrice || store.remixPrice === 0)) {
      PublishMenuStore.setPostErrorMessage(
        "You selected paid remix but did not provide a remix price."
      );
      return true;
    }

    // No errors found
    PublishMenuStore.setPostErrorMessage("");
    return false;
  }

  const saveRoom = async () => {
    if (!currentUser) {
      AuthenticationStore.setShowModal(true);
      return;
    }

    const cleanStr = (str) => (str ? str.replace(/\s+/g, "") : "");

    // Retrieve HTML, CSS, JS from localStorage
    const currentHTML = localStorage.getItem("currentHTML") || "";
    const currentCSS = localStorage.getItem("currentCSS") || "";
    const currentJS = localStorage.getItem("currentJS") || "";

    // Simplified title and description checks
    const isTitleEmpty = !PublishMenuStore.title?.trim();
    const isDescriptionEmpty = !PublishMenuStore.description?.trim();

    if (isTitleEmpty || isDescriptionEmpty) {
      PublishMenuStore.setShowErrorMessage(true);
      PublishMenuStore.setShowErrorMessageTitle(isTitleEmpty);
      PublishMenuStore.setShowErrorMessageDescription(isDescriptionEmpty);
      PublishMenuStore.setPostErrorMessage(
        "You are missing a title and/or description"
      );
      return;
    }

    if (checkForErrors()) {
      return; // Assuming checkForErrors handles its own error messaging
    }

    const html = DHTML_Store.html || "";
    const css = DHTML_Store.css || "";
    const js = DHTML_Store.js || "";

    const fileNameHTML = getFileName("html");
    const fileNameCSS = getFileName("css");
    const fileNameJS = getFileName("js");

    putObject("html", fileNameHTML, html);
    putObject("css", fileNameCSS, css);
    putObject("js", fileNameJS, js);

    const urlHTML = `https://${Config.HOST_CLOUDFRONT}/uploads/${fileNameHTML}`;
    const urlCSS = `https://${Config.HOST_CLOUDFRONT}/uploads/${fileNameCSS}`;
    const urlJS = `https://${Config.HOST_CLOUDFRONT}/uploads/${fileNameJS}`;

    const flowId = determineFlowId(); // Extract to a function for readability
    PublishMenuStore.setCreateFlowId(flowId);

    const collabRef = firestore.collection("collaborators");
    const currentUserDisplayName = currentUser.displayName;

    const query = collabRef.where(
      "additionalData.collaboratorId",
      "==",
      currentUserDisplayName
    );
    const snapshot = await query.get();

    if (!snapshot.empty) {
      const docId = snapshot.docs[0].id;
      await collabRef.doc(docId).update({
        "additionalData.skill": EditorStore.yourRole || "not listed",
      });
    } else {
      await collabRef.add({
        // other fields...
        additionalData: {
          // other fields...
          skill: EditorStore.yourRole || "not listed",
        },
      });
    }

    await createRoom(null, urlHTML, urlCSS, urlJS, flowId);
    updateUIAfterSave(); // Extract UI updates to a function for readability
  };

  // Additional helper functions for readability and maintainability
  function determineFlowId() {
    let flowId;
    const parts = window.location.pathname.split("/");
    const lastSegment = parts.pop() || parts.pop();

    if (lastSegment !== "new" && isUpdate) {
      flowId = lastSegment;
    } else {
      const hashids = new Hashids(uuid(), 6);
      flowId = hashids.encode(1, 2, 3);
    }

    return flowId;
  }

  function updateUIAfterSave() {
    const updateElementVisibility = (id, displayStyle) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = displayStyle;
      }
    };

    updateElementVisibility("postbtn", "none");
    updateElementVisibility("savechanges", "flex");
    updateElementVisibility("deletebtn", "flex");
  }

  const handleClick = () => {
    if (currentUser !== null && currentUser.displayName !== null) {
      // console.log("remixedby", remixedByArr);
      let parts = window.location.pathname.split("/");
      // let parts = window.location.pathname.split("/");
      let lastSegment = parts.pop() || parts.pop();
      if (FlowStore.isFlowOwner === false && lastSegment !== "new") {
        PublishMenuStore.setFlowIdOP(lastSegment);
        contentFilter();
        PublishMenuStore.setShowShare(true);
        if (document.getElementById("postbtn")) {
          document.getElementById("postbtn").style.display = "none";
        }
        if (document.getElementById("savechanges")) {
          document.getElementById("savechanges").style.display = "flex";
        }
        if (document.getElementById("deletebtn")) {
          document.getElementById("deletebtn").style.display = "flex";
        }
      } else {
        contentFilter();
      }
    } else {
      AuthenticationStore.setShowModal(true);
      WidgetStore.setShowPostModal(false);
    }
  };

  let putObject = async (type, fileName, data) => {
    // alert('putObject 3');

    let params = {
      Bucket: Config.HOST_S3,
      Key: "uploads/" + fileName,
      ContentEncoding: "base64",
      ContentType: getMimeType(type),
      Body: data,
    };

    console.log("putObject 5");

    s3.putObject(params, function (err, data) {
      if (err) {
        console.log("error :", err);
      } else {
        console.log("data :", data);
        let obj = {
          url: `https://${Config.HOST_CLOUDFRONT}/uploads/${fileName}`,
        };
        console.log("obj :", obj);
      }
    });
  };

  let getMimeType = (type) => {
    let mimeType = "";
    switch (type) {
      case "html":
        mimeType = "text/html";
        break;
      case "css":
        mimeType = "text/css";
        break;
      case "js":
        mimeType = "text/javascript";
        break;
    }

    return mimeType;
  };
  let getFileName = (type) => {
    let extension = "";
    switch (type) {
      case "html":
        extension = ".html";
        break;
      case "css":
        extension = ".css";
        break;
      case "js":
        extension = ".js";
        break;
    }
    let hashids = new Hashids(uuid(), 6);
    let fileName = hashids.encode(1, 2, 3) + extension;
    return fileName;
  };

  // const SplitButton = () => {
  //   const handleMenuItemClick = (event, index) => {
  //     setPostSelectedIndex(index);
  //     setOpen(false);
  //   };

  //   const handleToggle = () => {
  //     if (
  //       document.getElementsByClassName("publish-visibility")[0].style.zIndex ==
  //       100000
  //     ) {
  //       document.getElementsByClassName(
  //         "publish-visibility"
  //       )[0].style.zIndex = 100;
  //     }

  //     setOpen((prevOpen) => !prevOpen);
  //   };

  //   const handleClose = (event) => {
  //     if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //       return;
  //     }

  //     setOpen(false);
  //   };

  //   useEffect(() => {
  //     //console.log("[Publish_Save] useEffect");
  //     if (FlowStore.isFlowOwner) {
  //       //or username matches list of collaborators and there is a permission
  //       options = ["POST", "UPDATE"];
  //       if (!isPostOptionsLoaded) {
  //         setIsPostOptionsLoaded(true);
  //         setPostSelectedIndex(1);
  //       }
  //     } else {
  //       if (window.location.href.split("/")[5] !== "new") {
  //       } else {
  //         options = ["POST"];
  //       }
  //     }
  //   }, [FlowStore.isFlowOwner]);

  //   return (
  //     <Grid container direction="column" alignItems="center">
  //       <Grid
  //         item
  //         xs={12}
  //         style={{
  //           transform: "translate3d(0px, 0px, -92px)",

  //           width: FlowStore.isFlowOwner
  //             ? props.isMobile
  //               ? "197px"
  //               : "165px"
  //             : "",
  //         }}
  //       >
  //         {FlowStore.isFlowOwner ? (
  //           <ButtonGroup
  //             variant="contained"
  //             color="primary"
  //             ref={anchorRef}
  //             aria-label="split button"
  //           >
  //             <Button
  //               style={{
  //                 borderRadius: "0px 4px 4px 0px",
  //                 // height: "35px",
  //                 border: "0px",
  //                 fontWeight: 700,
  //                 backgroundColor: "#F06263",
  //                 padding: 0,
  //               }}
  //               color="primary"
  //               variant="contained"
  //               size="small"
  //               aria-controls={open ? "split-button-menu" : undefined}
  //               aria-expanded={open ? "true" : undefined}
  //               aria-label="select merge strategy"
  //               aria-haspopup="menu"
  //               onClick={handleToggle}
  //             >
  //               <ArrowDropDownIcon />
  //             </Button>
  //           </ButtonGroup>
  //         ) : (
  //           <Button
  //             style={{
  //               borderRadius: "20px",
  //               width: "100%",
  //               // maxWidth: 200,
  //               // height: "35px",
  //               border: "0px",
  //               backgroundColor: "#F06263",
  //               fontWeight: 700,
  //               fontSize: 15,
  //             }}
  //             color="primary"
  //             variant="contained"
  //             onClick={handleClick}
  //           >
  //             {options[postSelectedIndex]}
  //           </Button>
  //         )}

  //         <Popper
  //           open={open}
  //           anchorEl={anchorRef.current}
  //           role={undefined}
  //           transition
  //           disablePortal
  //           style={{ width: "100%" }}
  //         >
  //           {({ TransitionProps, placement }) => (
  //             <Grow {...TransitionProps}>
  //               <Paper
  //                 style={{
  //                   transform: "translate3d(0px, 31px, -92px)",
  //                   width: "100%",
  //                 }}
  //               >
  //                 <ClickAwayListener>
  //                   <MenuList id="split-button-menu">
  //                     {options.map((option, index) => (
  //                       <MenuItem
  //                         key={option}
  //                         selected={postSelectedIndex}
  //                         onClick={(event) => handleMenuItemClick(event, index)}
  //                       >
  //                         {option}
  //                       </MenuItem>
  //                     ))}
  //                   </MenuList>
  //                 </ClickAwayListener>
  //               </Paper>
  //             </Grow>
  //           )}
  //         </Popper>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  return (
    <div
      id="publish-section"
      style={{
        // height: props.height,
        display: "flex",
        zIndex: 100,
        justifyContent: "center",
        alignItems: "center",

        position: "relative",
        width: "100%",
        left: "0px",
        marginTop: 0,
        marginBottom: 0,
        display: EditorStore.showPostOptions == true ? "block" : "none",
      }}
    >
      <div
        style={{
          width: "100%",
          paddingTop: 27,
          paddingLeft: 22,
          paddingRight: 22,
          marginTop: 30,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          id="publish-menu"
          style={{
            display: "flex",
            alignItems: "end",
            borderTop: "0px solid rgb(29,29,29)",
            position: "relative",
            zIndex: 9999999,
            flexDirection: "column",
            // maxWidth: props.maxWidth,
          }}
        >
          {/* {FlowStore.isFlowOwner ? (
          <button
            className="red-button"
            onClick={() => {
              if (deleteModal == false) {
                setDeleteModal(true);
              } else {
                setDeleteModal(false);
              }
            }}
            // variant="contained"
            style={{
              // width: "100%",
              // maxWidth: 205,
              // height: "35px",
              // border: "0px",
              fontWeight: 700,
              fontSize: 17,

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
              width: "100%",
              borderRadius: 10,
              // backgroundColor: "F06263",
              fontWeight: 900,
              color: "#000",
              fontFamily: "quicksand",
              fontWeight: "bold",
              marginBottom: 3,
              marginTop: 10,
              overflow: "hidden",
            }}
            color="primary"
          >
            Delete
          </button>
        ) : (
          ""
        )} */}
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            {
              <p
                style={{
                  color: "#F06263",
                  fontFamily: "quicksand",
                  fontSize: 12,
                }}
              >
                {PublishMenuStore.postErrorMessage}
              </p>
            }
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <button
                className="green-button"
                style={{
                  width: FlowStore.isFlowOwner ? "100%" : "100%",
                  // maxWidth: 200,
                  // height: "35px",
                  // backgroundColor: "rgb(79, 217, 117)",
                  fontWeight: 700,
                  fontSize: 15,

                  display:
                    FlowStore.isFlowOwner && lastSegment !== "new"
                      ? "flex"
                      : "none",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "35px",
                  width: "100%",
                  borderRadius: 10,
                  // backgroundColor: "F06263",
                  fontSize: 17,
                  fontWeight: 900,
                  color: "#000",
                  fontFamily: "quicksand",
                  fontWeight: "bold",
                  marginBottom: 3,
                  marginTop: 10,
                  overflow: "hidden",
                  marginRight: 10,
                }}
                // variant={"contained"}
                onClick={() => {
                  WidgetStore.setLoadingOneMoment(true);
                  isUpdate = true;
                  handleClick();
                }}
              >
                Update
              </button>

              <button
                className="green-button"
                style={{
                  width: FlowStore.isFlowOwner ? "100%" : "100%",
                  fontWeight: 700,
                  fontSize: 15,
                  boxSizing: "border-box",

                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "11px 17px",
                  height: 35,
                  borderRadius: "10px",
                  fontFamily: "Quicksand",
                  fontStyle: "normal",
                  fontWeight: 700,
                  color: "#000",
                  marginTop: 10,
                  marginBottom: 20,
                }}
                // variant={"contained"}
                onClick={() => {
                  WidgetStore.setLoadingOneMoment(true);

                  setPostSelectedIndex(0);
                  isUpdate = false;

                  handleClick();
                }}
                disabled={disableSend}
              >
                {disableSend ? (
                  <>
                    <div className="animated-dot" />
                    <div
                      className="animated-dot"
                      style={{ animationDelay: "0.5s" }}
                    />
                    <div
                      className="animated-dot"
                      style={{ animationDelay: "1s" }}
                    />
                  </>
                ) : (
                  <p
                    style={{
                      color: "#000",
                      fontFamily: "quicksand",
                      fontWeight: 700,
                    }}
                  >
                    {!FlowStore.isFlowOwner && FlowStore.currentRoute !== "new"
                      ? "Post Remix"
                      : ""}
                    {FlowStore.isFlowOwner ? "Post As New Flow" : ""}
                    {FlowStore.currentRoute == "new" ? "Post Flow" : ""}
                  </p>
                )}
              </button>
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ReCAPTCHA
              sitekey="6Lf2Gk8UAAAAAM0PYHAgWAi_zW3EKWGD0ZkTGjDC"
              onChange={(value) => setRecaptchaValue(value)}
              theme="dark"
              size="compact"
            />
          </div> */}
        </div>
        <ReactModal
          isOpen={deleteModal}
          ariaHideApp={false}
          closeTimeoutMS={500}
          style={{
            overlay: {
              padding: 0,
              zIndex: 1000000,
              background: "rgba(0,0,0,0.5)",
            },
            content: {
              padding: 0,
              zIndex: 1000000,
              height: 400,
              maxWidth: 700,
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 7,
              background: "#3F3F3E",
              border: "5px solid #2D2A2B",
              margin: "auto",
              overflow: "hidden",
            },
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="#f06263"
            size={"4x"}
            style={{
              display: "block",
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setDeleteModal(false);
            }}
          />
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "537px",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: 22,
                    fontFamily: "quicksand",

                    width: "calc(100% - 50px)",
                    textAlign: "center",
                    marginBottom: "20px",
                    maxWidth: "440px",
                  }}
                >
                  Are you sure you want to delete this flow?
                </p>
                {/* <p
                  style={{
                    color: "white",
                    fontSize: 22,
                    marginBottom: 40,
                    fontFamily: "quicksand",
                  }}
                >
                  this flow?
                </p> */}
              </div>
              <div
                onClick={() => {
                  setDeleteModal(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "calc(100% - 55px)",

                  maxWidth: "300px",
                  border: "1px solid rgb(79, 217, 117)",
                  borderRadius: 5,
                  marginBottom: 20,

                  backgroundColor: "transparent",
                  borderRadius: "40px",
                  cursor: "pointer",
                  padding: "0px 15px",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: 18,
                    fontFamily: "quicksand",
                  }}
                >
                  Nope.
                </p>
              </div>
              <div
                className="green-button"
                onClick={() => {
                  console.log(
                    "[Publish_Save] delete clicked flowId:",
                    FlowStore.flowId,
                    " isFlowOwner:",
                    FlowStore.isFlowOwner
                  );
                  if (FlowStore.flowId && FlowStore.isFlowOwner) {
                    firebase
                      .firestore()
                      .collection("flows")
                      .doc(FlowStore.flowId)
                      .delete()
                      .then(() => {
                        if (
                          PublishMenuStore.flowIdOP !== null &&
                          PublishMenuStore.flowIdOP !== undefined &&
                          PublishMenuStore.flowIdOP.trim() !== ""
                        ) {
                          firebase
                            .firestore()
                            .collection("flows")
                            .doc(PublishMenuStore.flowIdOP)
                            .set(
                              {
                                remixedCount:
                                  firebase.firestore.FieldValue.increment(-1),
                              },
                              { merge: true }
                            )
                            .then(() => {
                              firebase
                                .firestore()
                                .collection("remixes")
                                .where("flowId", "==", FlowStore.flowId)
                                .get()
                                .then((flow) => {
                                  flow.forEach(function (doc) {
                                    doc.ref.delete();
                                    setDeleteModal(false);
                                    window.location.replace("/");
                                  });
                                })
                                .catch(() => {
                                  setDeleteModal(false);
                                  window.location.replace("/");
                                });

                              // setDeleteModal(false);
                              window.location.replace("/");
                            });
                        } else {
                          setDeleteModal(false);
                          window.location.replace("/");
                        }
                      });
                  }
                }}
                style={{
                  display: "flex",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 30,
                  width: "calc(100% - 55px)",
                  maxWidth: "300px",
                  border: "0px solid #40ffe8",
                  borderRadius: 5,
                  marginBottom: 20,

                  // backgroundColor: "rgb(79, 217, 117)",
                  borderRadius: "40px",
                  cursor: "pointer",
                  padding: "0px 15px",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontSize: 18,
                    fontFamily: "quicksand",
                  }}
                >
                  Yes, delete it.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "100%",
              width: "100%",
              background: "rgb(24,24,24)",
            }}
          ></div>
        </ReactModal>

        <ReactModal
          isOpen={emptyDHTML}
          ariaHideApp={false}
          closeTimeoutMS={500}
          style={{
            overlay: {
              padding: 0,
              zIndex: 1000000,
              background: "black",
              opacity: "0.9",
            },
            content: {
              padding: 0,
              zIndex: 1000000,
              height: 400,
              width: "87%",
              maxWidth: 700,
              background: "#3F3F3E",
              border: "5px solid #2D2A2B",
              borderRadius: 7,

              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              margin: "auto",
            },
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color="#f06263"
            size={"4x"}
            style={{
              display: "block",
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => setEmptyDHTML(false)}
          />
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: 22,
                      fontFamily: "quicksand",
                    }}
                  >
                    You posted or updated before the flow was loaded.
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontSize: 22,
                      marginBottom: 40,
                      fontFamily: "quicksand",
                    }}
                  >
                    Please try again
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setEmptyDHTML(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 30,
                  border: "1px solid #40ffe8",
                  borderRadius: 5,
                  marginBottom: 20,
                  cursor: "pointer",
                  margin: "auto",
                  width: "calc(100% - 100px)",
                  maxWidth: "300px",
                  border: "1px solid rgb(79, 217, 117)",
                  borderRadius: 5,
                  marginBottom: 20,

                  backgroundColor: "#4FD975",
                  borderRadius: "40px",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: 18,
                    fontFamily: "quicksand",
                  }}
                >
                  Ok
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "100%",
              width: "100%",
              background: "rgb(24,24,24)",
            }}
          ></div>
        </ReactModal>
      </div>
    </div>
  );
});

export default React.memo(Publish_Save);
