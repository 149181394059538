// libraries
// import { css } from "@emotion/core";
// libraries
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AWS from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
import { observer } from "mobx-react-lite";
import { default as React, useContext, useEffect, useState, useRef } from "react";
import "react-tabs/style/react-tabs.css";
import { Tab } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { AuthenticationStoreContext } from "../../../store/AuthenticationStore.js";
import { CommentsStoreContext } from "../../../store/CommentsStore.js";
import { DrawerStoreContext } from "../../../store/DrawerStore.js";
import { EditorStoreContext } from "../../../store/EditorStore.js";
import { FlowStoreContext } from "../../../store/FlowStore.js";
import { PublishMenuStoreContext } from "../../../store/Publish_Menu.js";
import { RemixableStoreContext } from "../../../store/RemixableStore.js";
import { RouteStoreContext } from "../../../store/RouteStore.js";
import { ShareStoreContext } from "../../../store/ShareStore.js";
import { WidgetStoreContext } from "../../../store/WidgetStore.js";
import { TabStoreContext } from "../../../store/TabStore.js";
import { useLocation } from "react-router-dom";
import Checkbox from "react-custom-checkbox";
import { firebase } from "../../firebase/firebase.js";
import Editor from "../Editor/Editor.js";
import Remix_Menu_Desktop from "../Remix_Menu_Desktop.js";
import VoiceClone from "../VoiceClone.js";
import styles from "./Room.module.css";
import DeleteModal from "./deleteModal/deleteModal.js";
import ErrorMessage from "./errorMessage/errorMessage.js";
import SaveMessage from "./saveMessage/saveMessage.js";
import SidebarMenu from "../SlidebarMenu.js"
import { useParams } from 'react-router-dom';

import { AuthContext } from "../AuthContext.js";
import ReactModal from "react-modal";
import CommentsMobile from "../RoomComponents/CommentsMobile.js";
import LoginM from "../LoginM.js";
import SigninM from "../SigninM.js";
import SetAdvancedModal from "../Editor/EditorModals/set-advanced-modal.js";
import algoliasearch from 'algoliasearch';
import OpenAI from 'openai';
import Replicate from "replicate";
import Header from "../Header.js";
const replicate = new Replicate({
  // get your token from https://replicate.com/account/api-tokens
  auth: "r8_OhJakh7ojwpl6rFzC1QivJosEXYlzpX25i63B", // defaults to process.env.REPLICATE_API_TOKEN
});
const openai = new OpenAI({
  apiKey:'sk-5MFKnG1JLcCKgcAEQW3eUpQX0QaTTIDewmXSylCG', // This is the default and can be omitted
  dangerouslyAllowBrowser: true
});
const { v4: uuidv4 } = require('uuid');
const algoliaClient = algoliasearch('F0LCATOZYQ', '52cf76114641d0f9464ec522f4685d5a');
const index = algoliaClient.initIndex('flowroom');

// import SetupOptions from "./setupOptions/setupOptions.js";
// import DetailsModal from "./DetailsModal.js";
// // content
// import AddWidgetErrorMessage from "./AddWidgetErrorMessage.js";

// import AddRemixableWidget from "./AddRemixableWidget.js";
// import AdvancedWarningModal from "./AdvancedWarning.js";
// import Header from "./Header";
// import RemixModal from "./RemixModal.js";

// import CodeWarningModal from "./CodeWarningModal.js";

// import Remix_Menu_Desktop from "./Remix_Menu_Desktop";
// import ShareModal from "./ShareModal.js";
// // modals
// import CommentsModal from "./CommentsModal";
// import DetailsDescriptionModal from "./DetailsDescriptionModal";
// import RemixesModal from "./RemixesModal";

// import TagsInput from "./TagsInput";

// import NewWidgetWarningMessage from "./NewWidgetWarningMessage.js";

const DetailsModal = React.lazy(() => import("../DetailsModal.js"));

const AddWidgetErrorMessage = React.lazy(() =>
  import("../AddWidgetErrorMessage.js")
);

const AdvancedWarningModal = React.lazy(() => import("../AdvancedWarning.js"));

// const Editor = React.lazy(() => import("../Editor/Editor.js"));

const CodeWarningModal = React.lazy(() => import("../CodeWarningModal.js"));

const ShareModal = React.lazy(() => import("../../Home/Editor/EditorModals/share-modal.js"));

const NewWidgetWarningMessage = React.lazy(() =>
  import("../NewWidgetWarningMessage.js")
);

let parts = window.location.pathname.split("/");
// let parts = window.location.pathname.split("/");
let id = parts.pop() || parts.pop();
window.confirmedCredits = false;
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#222222",
        borderWidth: 4,
      },
      "&:hover fieldset": {
        borderColor: "#6371F6",
        borderWidth: 4,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6371F6",
      },
    },
  },
})(TextField);

let isInitRoom = false;
let isMenuAlreadySetToFalse = false;
let publishModalLoaded = false;
let checkStatusOfRun;

const steps = [
  {
    selector: "#horizontal-tab-code-tab",
    content: "This is my first Step",
  },
  // ...
];

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

let objArray = [];
// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;
var GphApiClient = require("giphy-js-sdk-core");
let client = GphApiClient("ybxqH0QDbtfnHTrTrFJ0BmLMX6QpEpWu");
const CustomTab = ({ children }) => (
  <Tab>
    <h1>{children}</h1>
  </Tab>
);

CustomTab.tabsRole = "Tab"; // Required field to use your custom Tab
let data = -1;

AWS.config.update({
  region: "us-west-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-west-2:5df2511a-5595-416c-b148-aba28893c3f3",
  }),
});

const s3 = new S3();

var moment = require("moment");
let timer = null;
const messages = [];
let names = [];
let messagesSent = [];
let messageList = [];
let preventDuplicateArray = []; //keeps track
let postData = [];
let callOnce = false;
let isUploaded = false;
let objects_arr = [];

let elements_arr = [];
let element_options = ["GIFS", "STICKERS", "EMOJIS", "TEXT"];

let Loaded = false;
let isMenuOpen = false;
let addedBefore = false;
let roomsPerPage = 4;
let roomFilter = "weight";
let relatedRooms = [];
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters1 = [KeyCodes.comma, KeyCodes.enter];
const delimiters2 = [KeyCodes.comma, KeyCodes.enter];
const delimiters3 = [KeyCodes.comma, KeyCodes.enter];
const delimiters4 = [KeyCodes.comma, KeyCodes.enter];
const delimiters5 = [KeyCodes.comma, KeyCodes.enter];
let thumbPicURL;
let infinityIcon = `url(../images/infinity_cyan.svg)`;
let infinityIconGray = `url(../infinity_grey.svg)`;

const incrementViews = () => {
  // TODO: id replace with FlowStore
  let parts = window.location.pathname.split("/");
  let id = parts.pop() || parts.pop();
  if (id && id != "" && id != "new") {
    firebase
      .firestore()
      .collection("flows")
      .doc(id)
      .set(
        { views: firebase.firestore.FieldValue.increment(1) },
        { merge: true }
      );
  }
};
let stepConversationHistory = [{role:"system", content:`You are an AI chatbot that takes information provided, usually instructions and returns an array of strings breaking the instructions up into steps. 
Try to make each string as short and concise as possible. You may get an entire paragraph explaining how to do something and you'll need to turn it into steps as in short sentences that are strings in an array.
Do not include anything other than the steps or instructions, not friendly conversation or anything other than steps to follow. You also need to have a property called "showInstructions" that is a boolean. It is true if the information you are provided with are instructions and false if its just a regular response like an answer to a question that isnt instructions to follow.

IMPORTANT: If you set "showInstructions" to true because the information is a set of instructions, keep this true regardless of what other things are said even if unrelated unless the user indicates that they want to cancel or quit or stop following instructions or start over again. If the user wants to start over again, then remember the "currrentStepIndex" is 0 again.
You should return a JSON object that looks like:

{
  "steps":[{ step:'step here', image:'image if it was supplied for the step ( if provided )', video:'video if it was supplied with the step ( if provided )' }, { step:'step here', image:'image if it was supplied for the step ( if provided )', video:'video if it was supplied with the step ( if provided )' }, etc],
  "response": string introducing the instructions or steps,
  "supplies": [a list of things you'll need to carry out of instructions such as ingredients or tools etc. as an array of objects like { supply: 'name of supply such as an ingredient or tool', image: 'image of the supply ( if provided )', purchaseLink: 'link to where you can purchase the supply ( if provided )' } ]
  "showInstructions": boolean true or false depending on if should show instructions.
  "currentStepIndex": The current step you are on after completing the previous step. The first step would be 0 since the steps array contains the steps and starts with 0 index.
}

VERY IMPORTANT: Maintain the steps you originally give so that when a step is completed, the next step is the same as initially so the user doesn't lose track. Do not shorten the step or truncate. Make sure it's the same. 
IMPORTANT: the first step in the steps array is 0, so "currentStepIndex" would start with that but when a user completes a step, for example the first step, "currentStepIndex" would become 1 or 2 etc depending on what step is complete.
IMPORTANT: Remember if "currentStepIndex" is 0 then that means the user is working on Step 1 and may mention they completed step 1 or share something to indicate they completed Step 1 and upon completion, when "currentStepIndex" is 1, that means the user is now on Step 2 etc.

The JSON object should also have a response property containing a nice introductory message before the steps like title of a recipe or if not a recipe the title of whatever it is you're giving step by step instructions to.
You also need a supplies property.`}];
const Room = observer((props) => {
  // console.log("[Room]", props.match.params.id);
  const [flowData, setFlowData] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const RouteStore = useContext(RouteStoreContext);
  const FlowStore = useContext(FlowStoreContext);
  const PublishMenuStore = useContext(PublishMenuStoreContext);
  const DrawerStore = useContext(DrawerStoreContext);
  const RemixableStore = useContext(RemixableStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const WidgetStore = useContext(WidgetStoreContext);
  const CommentsStore = useContext(CommentsStoreContext);
  const ShareStore = useContext(ShareStoreContext);
  const TabStore = useContext(TabStoreContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { roomId, commentId, replyId } = useParams(); // Extract both roomId and commentId
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [value, setValue] = React.useState("recents");
  const [botInfoObject, setBotInfoObject] = useState([]);
  const [personalityStyle, setPersonalityStyle] = useState('');
  const [bio, setBio] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [userDecision, setUserDecision] = useState(null);
  const [methodName, setMethodName] = useState('');
  const [currentMethod, setCurrentMethod] = useState('');
  const lockRef = useRef(null); // Lock to control access to the modal
  const [isLocked, setIsLocked] = useState(false);
  const [userHasConfirmed, setUserHasConfirmed] = useState(false);
  const [modalInfo, setModalInfo] = useState({ isOpen: false, method: null });
  const [promiseResolver, setPromiseResolver] = useState(null);
  const [totalCredits, setTotalCredits] = useState(0);
  const [dontShow, setDontShow] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [isBlocked, setIsBlocked] = useState(false);
  const [roomShowLoginModal, setRoomShowLoginModal] = useState(false);

  const [messagesToBot, setBotMessages] = useState([{ role:'system', content: `You are an AI chatbot tasked with interacting with users according to specific guidelines and context provided. Your responses are influenced by a provided bio, which details your character and background, and a personality style, which dictates the manner in which you should communicate.

  If neither a bio nor a personality style is provided: Maintain a standard, neutral chatbot demeanor that provides helpful, straightforward answers.
  
  IMPORTANT: respond with a JSON object containing the botName, personalityStyle, bio, properties and their values as well as a respondWithAdditionalInfo property that is a boolean and is true if it seems like there needs to be an answer that is more elaborate involving more than just personality but specific knowledge, links, advice, or anything that falls under the following categories:

  Personal Traits (e.g., Aggressiveness, Impulsiveness, Empathy)
  Emotional States (e.g., Happiness, Sadness, Anxiety)
  Philosophical Insights (e.g., Morality, Existentialism, Spirituality)
  Specialized Knowledge (e.g., Culinary Arts, Science, History)
  Practical Guidance (e.g., DIY, Home Repair, Personal Finance)
  Professional Expertise (e.g., Engineering, Medical, Finance)
  Cultural Perspectives (e.g., Art, Music, Traditions)
  Health and Wellness (e.g., Fitness, Nutrition, Mental Health)
  Political Views (e.g., Liberalism, Conservatism, Public Policy)
  Life Experiences (e.g., Travel, Parenting, Overcoming Challenges)
  Educational Content (e.g., Mathematics, Literature, Languages)
  Technological Advancements (e.g., Artificial Intelligence, Blockchain, Cybersecurity)
  Hobbies and Interests (e.g., Gardening, Photography, Gaming)
  Relationships (e.g., Friendship, Romantic Relationships, Family Dynamics)
  Philosophical and Spiritual Beliefs (e.g., Religion, Mindfulness, Meditation)

  otherwise, set the respondWithAdditionalInfo property to false. 

  Also if there is no need to set respondWithAdditionalInfo to true, give your response that's based on bio and or personalityStyle (if provided) as a string for a property "basicResponse" which contains the response.  

  In all cases, your primary objective is to stay true to the personality style described. If this style is not provided, base your responses on the bio details. If neither a bio nor a personality style is specified, default to responding as a regular, informative chatbot. Your responses should always strive to be insightful and contextually relevant, maintaining character integrity and providing user-focused interaction.` }]);



  const [messagesToUseBot, setMessagesToUseBot] = useState([{ role:'system', content: `You are an AI chatbot tasked with interacting with users according to specific guidelines and context provided. Your responses are influenced by a provided bio, which details your character and background, and a personality style, which dictates the manner in which you should communicate.

  When provided with potentialHelpfulInformation: YOU MUST USE the information from it that is the most relevant piece from up to three options to address the user's question effectively. Ensure your response makes this information clear and contextually appropriate, enhancing it if it lacks sufficient context or clarity.
  
  If no potentialHelpfulInformation is provided: Respond to the user's query to the best of your ability, strictly adhering to the personality style and characteristics outlined in the bio.
  
  If neither a bio nor a personality style is provided: Maintain a standard, neutral chatbot demeanor that provides helpful, straightforward answers.

  IMPORTANT: Try to speak in a context independent way but also as if you were already having a conversation. Where what you say will most likely blend in with a conversation that was already happening.
  IMPORTANT: When provided with instructions, use the instructions provided but just reword it to fit with the personality etc. Do not make up or add your own improvised instructions unless necessary. 
  In all cases, your primary objective is to stay true to the personality style described. If this style is not provided, base your responses on the bio details. If neither a bio nor a personality style is specified, default to responding as a regular, informative chatbot. Your responses should always strive to be insightful and contextually relevant, maintaining character integrity and providing user-focused interaction.` }]);

  const chunkSize = 1400; // maximum size of each text chunk
  const waitTime = 2000; // milliseconds to wait between processing each chunk


  useEffect(()=>{
    if(document.getElementsByTagName('html') !== null) {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
    },[])

    useEffect(()=>{
      // setRoomShowLoginModal(true)
    },[AuthenticationStore.roomShowLoginModal])



  function printAnalysisDetails(analysisData) {
    let fullOutput = ''; // Initialize an empty string to accumulate all entries

    analysisData.forEach(item => {
        const title = item.title || 'No Title Provided';
        const subCategory = item.subCategory || 'No Subcategory Provided';
        const analysis = item.analysis || 'No Analysis Provided';
        const keywords = item.keywords ? item.keywords.join(', ') : 'No Keywords Provided';
        const links = item.links ? item.links.join(', ') : 'No Links Provided';
        const youtubeLinks = item.youtubeLinks ? item.youtubeLinks.join(', ') : 'No YouTube Links Provided';

        // Building the output string for the current item
        const output = `
Title: ${title}
Sub-category: ${subCategory}
Analysis: ${analysis}
Keywords: ${keywords}
Links: ${links}
YouTube Links: ${youtubeLinks}
------------------------------------------`;

        // Adding the current item's output to the full output
        fullOutput += output + '\n'; // Adding a newline for separation between entries
    });

    // Logging the full output after building it from all entries
    return fullOutput;
}

const loadFromAlgolia = async (botName, keywords, subCategory = null, startIndex = 0) => {
  let filters = `botName:${botName}`;
  // if (subCategory) {
  //   filters += ` AND subCategory:${subCategory}`;
  // }

  try {
    const response = await index.search(keywords.join(' '), {  // Using space to join keywords
      hitsPerPage: 50,  // Ensures a maximum of 50 results per query
      page: startIndex,
    });
    console.log('response from algolia', response);
    console.log("Search Parameters:", JSON.stringify({ filters: filters, hitsPerPage: 50, page: startIndex }));
    const titles = response.hits.map((item, index) => `${index + 1}. ${item.title}`);
    return {titles:titles, response:response};
  } catch (error) {
    console.error('Error retrieving data from Algolia:', error);
    return null;
  }
};

const managerFunc = async (userInput) => {
  return new Promise((resolve, reject) => {
    fetch(
      "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages:userInput,
          model: "gpt-4o",
          temperature: 0,
          response_format: { "type": "json_object" }
        }),
      }
    )
      .then((response) => response.json())
      .then((chatText) => {
        console.log("from customChatbot", chatText);
        let result = chatText.choices[0].message.content;
        resolve(result);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

  const determineSubCategoryAndloadTitlesFromAlgolia = async (botName, userInput, chatOffset) => {
          let result = await determineSubcategoryAndKeywords(userInput);
          console.log('result', result)
          /* replace hard coded titles with the algolia function */
          /* what is no titles from algolia? */
          let resultFromAlgolia = await loadFromAlgolia(botName, JSON.parse(result).keywords, JSON.parse(result).subCategory );
          console.log('resultFromAlgolia', resultFromAlgolia);
          /* determine sub categories queries algolia and gets back documents */


        return resultFromAlgolia;
  }


  window.stepGPT = async (userInput) => {
 

    stepConversationHistory.push({role: "user", content: userInput });

    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:[...stepConversationHistory],
            model: "gpt-4o-mini",
            temperature: 0,
            response_format: { "type": "json_object" }
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          stepConversationHistory.push({role: "assistant", content: result });
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });

  }

  window.gpt = async (conversationArray, model, temperature, jsonResponse) => {

    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:conversationArray,
            model: model,
            temperature:temperature,
            response_format: jsonResponse == true ? { "type": "json_object" } : { "type": "text" }
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  // Define the saveData function
  window.saveFlowData = async function (flowId, publicData, privateData) {
    try {
      const db = firebase.firestore();
      const auth = firebase.auth();
  
      const currentUser = auth.currentUser;
  
      if (!currentUser) {
        throw new Error('User is not authenticated.');
      }
  
      const username = currentUser.displayName;
  
      if (!username) {
        throw new Error('User display name is not set.');
      }
      // alert('called')
      const flowDataRef = db.collection('flowData').doc(flowId);

      const data = {
        flowId: flowId,
        username: username,
        // Public and private data are stored in separate subcollections
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
  
      // Save or update the main flowData document
      await flowDataRef.set(data, { merge: true }); // merge: true to update only specified fields
  
      // Save publicData in the publicData subcollection
      const publicDataRef = flowDataRef.collection('publicData').doc('public');
      await publicDataRef.set(
        {
          ...publicData,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  
      // Save privateData in the privateData subcollection
      const privateDataRef = flowDataRef.collection('privateData').doc('private');
      await privateDataRef.set(
        {
          ...privateData,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  
      console.log(`Data for flowId "${flowId}" saved successfully.`);
    } catch (error) {
      console.error('Error saving data:', error);
      // Optionally, you can display an alert or notification to the user
      alert(`Failed to save data: ${error.message}`);
      throw error; // Re-throw to allow callers to handle it
    }
  };


  window.loadFlowData = function (flowId, callback) {
    const db = firebase.firestore();
    const auth = firebase.auth();
  
    const currentUser = auth.currentUser;
  
    const flowDataRef = db.collection('flowData').doc(flowId);
  
    // Set up a real-time listener on the flowData document
    const unsubscribe = flowDataRef.onSnapshot(
      async (doc) => {
        if (!doc.exists) {
          console.warn(`No document found with flowId "${flowId}".`);
          callback(null); // Pass null to indicate absence
          return;
        }
  
        const flowData = doc.data();
  
        // Fetch publicData
        const publicDataRef = flowDataRef.collection('publicData').doc('public');
        const publicDoc = await publicDataRef.get();
        const publicData = publicDoc.exists ? publicDoc.data() : {};
  
        let privateData = null;
  
        // If the user is authenticated and is the owner, fetch privateData
        if (currentUser && currentUser.displayName === flowData.username) {
          const privateDataRef = flowDataRef.collection('privateData').doc('private');
          const privateDoc = await privateDataRef.get();
          privateData = privateDoc.exists ? privateDoc.data() : {};
        }
  
        // Pass the data to the callback
        callback({
          publicData: publicData,
          privateData: privateData, // null if not the owner or unauthenticated
        });
      },
      (error) => {
        console.error('Error loading data:', error);
        alert(`Failed to load data: ${error.message}`);
        callback(null);
      }
    );
  
    // Return the unsubscribe function to allow the caller to stop listening
    return unsubscribe;
  };

  window.gptChatBot = async (conversationArray, model, temperature, jsonResponse) => {

    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:[...conversationArray],
            model: "gpt-4o",
            temperature: 0,
            response_format: { "type": "json_object" }
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });

  }

  window.runTestFurnitureApp = async (inputData) => {
    try {
      const response = await fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/useReplicateFurniture', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ input: inputData })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Model output:', result);
      return result;
    } catch (error) {
      console.error('Error when calling callReplicate:', error);
      throw error; // Re-throw to handle it elsewhere if needed
    }
  };
  
  window.tryOnClothing = async (input) => {
    try {
      const response = await fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/tryOnClothing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ input })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Model output:', result);
      return result;
    } catch (error) {
      console.error('Error when calling tryOnClothing:', error);
      throw error;
    }
  };

  window.currentUserName = () => {
    return currentUser.displayName;
  }
  
  window.youtubeTranscribe = async (input, flowId) => {
    try {
      const username = 'bosscodeman';
      const response = await fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/youtubeTranscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ flowId, username, input })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Model output:', result);
      return result;
    } catch (error) {
      console.error('Error when calling youtubeTranscribe:', error);
      throw error;
    }
  };
  // const addInviteCodeNotification = async (sender, receiver, message, flowId) => {
  //   const newNotification = {
  //     sender,
  //     receiver,
  //     type: "flowroomMethod",
  //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //     additionalData: { message, flowId },
  //   };
  //   await firebase.firestore().collection("notifications").add(newNotification);
  // };

  // window.sendNotification = async (usernames, message) => {
   
  //   addInviteCodeNotification("flowroom", username, "You have been granted developer access. You can now access the code editor and flowroom guide.", "noid")
  // }


  // Assuming Firebase has been initialized elsewhere in your application
  window.listenToAPIUpdate = (flowId, functionName, username_, callback) => {
    if (window.parent.isAuthenticated() !== true) {
      console.log('User is not authenticated. Listener not set up.');
      return;
    }
  
    const username = window.parent.currentUserName(); // Should return 'bosscodeman'
    console.log(`Username obtained: ${username}`);
  
    const db = firebase.firestore();
    const userDocRef = db.collection('flows').doc(flowId)
                          .collection(functionName)
                          .doc(username); // Direct access to user's document
  
    console.log(`Setting up Firestore listener on flows/${flowId}/${functionName}/${username}`);
  
    const unsubscribe = userDocRef.onSnapshot(
      (doc) => {
        console.log('onSnapshot triggered');
        if (doc.exists) {
          const data = doc.data();
          console.log('User Document data:', JSON.stringify(data, null, 2));
          let iframe = document.getElementById('undefined_output_frame');

        
         
  
          if (data) {
            try {
              if (typeof data.result === 'string') {
                const parsedResult = JSON.parse(data.result);
                console.log('Parsed result:', parsedResult);
                try {
                  // Call the function in the iframe
                  iframe.contentWindow[callback](parsedResult);
                } catch (error) {
                  console.error('Error calling iframe function:', error);
                }
              } else {
                console.log('Result:', data.result);
                try {
                  // Call the function in the iframe
                  iframe.contentWindow[callback](data.result);
                } catch (error) {
                  console.error('Error calling iframe function:', error);
                }
              }
            } catch (e) {
              console.error('Error parsing result:', e);
            }
          } else {
            console.log('No function results found for the username');
          }
        } else {
          console.log('No such document!');
        }
      },
      (error) => {
        console.error('Error listening to document:', error);
      }
    );
  
    return unsubscribe;
  };
  
  
  
  

  

  const determineSubcategoryAndKeywords = async (userInput) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:[{role:"system", content:`You are an AI chatbot that determines which sub category from the list of core categories provided below best fits the input from the user. You will also generate 20 keywords you also feel fit best.
            You will return JSON with a "subCategory" property and a "keywords" property.
            
            Personal Traits (e.g., Aggressiveness, Impulsiveness, Empathy)
            Emotional States (e.g., Happiness, Sadness, Anxiety)
            Philosophical Insights (e.g., Morality, Existentialism, Spirituality)
            Specialized Knowledge (e.g., Culinary Arts, Science, History)
            Practical Guidance (e.g., DIY, Home Repair, Personal Finance)
            Professional Expertise (e.g., Engineering, Medical, Finance)
            Cultural Perspectives (e.g., Art, Music, Traditions)
            Health and Wellness (e.g., Fitness, Nutrition, Mental Health)
            Political Views (e.g., Liberalism, Conservatism, Public Policy)
            Life Experiences (e.g., Travel, Parenting, Overcoming Challenges)
            Educational Content (e.g., Mathematics, Literature, Languages)
            Technological Advancements (e.g., Artificial Intelligence, Blockchain, Cybersecurity)
            Hobbies and Interests (e.g., Gardening, Photography, Gaming)
            Relationships (e.g., Friendship, Romantic Relationships, Family Dynamics)
            Philosophical and Spiritual Beliefs (e.g., Religion, Mindfulness, Meditation)

            The subCategory should be formatted with the same capital letters but no spaces so for example Personal Traits would be PersonalTraits but keywords should be lowercase.`}, {role: "user", content: userInput }],
            model: "gpt-4o",
            temperature: 0.2,
            response_format: { "type": "json_object" }
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const { currentUser } = useContext(AuthContext);

  const location = useLocation();



  useEffect(() => {
    const db = firebase.firestore()
    if (id && currentUser) {
      const currentUserDisplayName = currentUser.displayName;

      // Step 1: Query the `flows` collection to get the document where `flowId` matches the route :id
      db.collection("flows")
        .where("flowId", "==", id)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]; // Get the first document (assuming flowId is unique)
            const flowData = doc.data();
            const otherUserName = flowData.username; // Get the username from the flows document

            // Step 2: Query the `users` collection where `username` matches the one from the flows document
            db.collection("users")
              .where("username", "==", otherUserName)
              .get()
              .then((userSnapshot) => {
                if (!userSnapshot.empty) {
                  const userDoc = userSnapshot.docs[0];
                  const userData = userDoc.data();
                  const targetUserId = userData.userId; // Extract userId from users collection

                  // Step 3: Query the `blocked` collection where `userId` matches the obtained `targetUserId`
                  db.collection("blocked")
                    .where("userId", "==", targetUserId)
                    .get()
                    .then((blockedSnapshot) => {
                      blockedSnapshot.forEach((blockedDoc) => {
                        const blockedData = blockedDoc.data();

                        // Check if `blockedUser` in the `blocked` collection matches `currentUser.displayName`
                        if (blockedData.blockedUser === currentUserDisplayName) {
                          setIsBlocked(true); // Set state to true if the user is blocked
                        }
                      });
                    })
                    .catch((error) => {
                      console.error("Error fetching from blocked collection: ", error);
                    });
                }
              })
              .catch((error) => {
                console.error("Error fetching user data from users collection: ", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching flow data from flows collection: ", error);
        });
    }
  }, [id, currentUser]);





  useEffect(() => {
    // Modal opening logic corrected
    if (commentId) {
      // alert(commentId)
      setIsModalOpen(true);
  
    }
  
    // Clean-up function to ensure modal is closed when the component unmounts
    return () => setIsModalOpen(false);
  }, [commentId, replyId, setIsModalOpen]); // Correct dependencies
  
  useEffect(() => {
    if (isModalOpen && commentId) {
      // alert(commentId)
      setTimeout(() => {
        const element = document.getElementById(`comment-${commentId}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 5000);
    }
  }, [isModalOpen, commentId]);

  
  useEffect(()=>{
    setIsPanelOpen(TabStore.IsPanelOpen)

  },[TabStore.IsPanelOpen])


  const [loading, setLoading] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    // alert("called");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [fr_remix_list, setFR_Remix_list] = useState({
    FR_REMIX_IMAGE_LIST: [],
  });

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);

    DrawerStore.setState({ ...DrawerStore.state, ["bottom"]: true }, "e");
  };

  useEffect(() => {
    const rootElement = document.getElementById("root");
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  const ragLLM = async (titles, userInput) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: [{role:'system', content:`You are an AI-powered chatbot tasked with assessing the relevance of document titles in response to user queries. After evaluating the provided titles and the user's specific question or statement, you return a JSON object structured as follows:

            1. "titles": This property is an array containing up to three objects each with the best titles from a database and an "index" property that matches where they would be in an array. So on a number list like 1. Some title 2. Another title 3. And another title 4. Another new title, 5. Some title, if you felt 2 and 3 were the best, in the object you would have 1 and 2 for the index because in an array, 1 is 0. These titles are selected based on their potential to provide valuable answers or responses to the user's query.
            2. "results": A boolean property that indicates whether any titles were available to assess. It returns true if at least one title was evaluated, and false if no titles were available.
            3. "titlesWork": Another boolean property, which is true if you determine that there are three or fewer top titles that effectively address the user's needs based on the query provided.
            4. "offset": This property should be set to the number of the last title you assessed or listed. It helps in managing pagination or further queries, indicating where the next set of titles should begin if additional queries are necessary.

            Your task is to analyze the user's input, match it against a set of titles, and generate the JSON response based on these criteria."
            
            IMPORTANT: the titles from the user you choose must be titles that best fit what the user is asking a question about or making a statement about. For example if you have two titles
            IMORTANT: "results" is always set to true if you're provided titles.
            IMPORTANT: 

            EXAMPLE:
            titles from user: 1. A movie title or something
            2. A title about cooking hamburgers

            and a user asks, how can I make a hamburger? Obviously, the best title is: 2. A title about cooking hamburgers. Since there are only two titles, the best one is only that one. There are not more than one best title as a response to what the user said.

            This JSON object structure allows the chatbot to clearly communicate with the backend or further processing systems, detailing how effective the response was and where further interactions might continue if needed. It is an effective way to package the outcome of the chatbot's analysis into a structured format that can be easily understood and utilized in further computational processes or user interactions.`},  {
              role:'user', content:`titles from user: ${titles}
              user question or statement: ${userInput}`
            }],
            model: "gpt-4o",
            temperature: 0.2,
            response_format: { "type": "json_object" }
          }, {
            role:'user', content:`titles from user: ${titles}
            user question or statement: ${userInput}`
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const runBot = async (userInput) => {
    return new Promise((resolve, reject) => {
      fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/customChat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages:userInput,
            model: "gpt-4o",
            temperature: 0.2
          }),
        }
      )
        .then((response) => response.json())
        .then((chatText) => {
          console.log("from customChatbot", chatText);
          let result = chatText.choices[0].message.content;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };


  async function queryBotByName(botName) {
    try {
        const botsRef = firebase.firestore().collection('bots');
        const snapshot = await botsRef.where('botName', '==', botName).get();
        if (snapshot.empty) {
            console.log('No matching documents.');
            return [];
        }

        let data = [];
        snapshot.forEach(doc => {
            data.push({ id: doc.id, ...doc.data() });
        });
        return data;
    } catch (error) {
        console.error("Error fetching data: ", error);
        return []; // Return an empty array in case of error
    }
}



  // const checkMessages = async (threadId, runId) => {
  //   try {
  //     let runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);
  //     if (runStatus.status === "completed") {
  //       let messages = await openai.beta.threads.messages.list(threadId);
  //       messages.data.forEach((msg) => {
  //         const role = msg.role;
  //         const content = msg.content[0].text.value;
  //         console.log(`${role.charAt(0).toUpperCase() + role.slice(1)}: ${content}`);
  //         clearInterval(checkStatusOfRun);
  //       });
  //       return content;
  //     } else {
  //       console.log(`Run is not complete yet. Status: ${runStatus.status}`);
  //       // Log error details if the status is 'failed'
  //       if (runStatus.status === "failed") {
  //         console.error('Run failed. Error details:', JSON.stringify(runStatus.last_error, null, 2));
  //       }
        
  //     }
  //   } catch (error) {
  //     console.error('Error checking messages:', error);
  //   }
  // }

    useEffect(() => {
    // This effect will run whenever botInfoObject changes
    if (botInfoObject) {
    }
  }, [botInfoObject]);
  

  let threadId = null;
  let convoArrayForBasicBot = [];
  let botInfoObjectArr = [];
  let botInfo;
  let fullName_
  function extractTextAndJson(input) {
    const jsonRegex = /```json([^]*?)```|({[^]*})/g;
    const matches = [...input.matchAll(jsonRegex)];
    let jsonString = '';
    let textString = input;
  
    if (matches.length > 0) {
      jsonString = matches[0][1] ? matches[0][1].trim() : matches[0][2].trim();
      textString = input.replace(jsonRegex, '').trim();
    }
  
    let jsonObject = {};
    if (jsonString) {
      try {
        jsonObject = JSON.parse(jsonString);
      } catch (e) {
        console.error("Invalid JSON format:", e);
      }
    }
  
    return {
      content: textString,
      json: jsonObject
    };
  };

  function containsJson(text) {
    // Regular expression to find potential JSON
    const potentialJsonRegex = /({[^{}]*}|[[\]]*])/g;
    let match;
    let containsJson = false;
  
    // Loop over all regex matches
    while ((match = potentialJsonRegex.exec(text)) !== null) {
      try {
        // Attempt to parse each match as JSON
        JSON.parse(match[0]);
        containsJson = true; // Valid JSON found
        break; // Exit loop if valid JSON is found
      } catch (e) {
        // Continue if JSON.parse throws an error, indicating invalid JSON
      }
    }
  
    return containsJson;
  }
  let isCancelled = false;

// Function to trigger cancellation
window.cancelOperation = () => {
  isCancelled = true;
};


  window.runChatbot = async (userInput, botName, model = 'gpt-4o', temperature = 0.2, jsonResponse = false) => {
    // Update the state to include the new user message
    let docStore = [];

    const userMessageToBot = {
        role: 'user',
        content: userInput
    };
    
    try {
        if(isCancelled) {
          console.log('Operation cancelled before starting.');
          return;
        }
    
        console.log('botInfoObject', botInfoObjectArr);
    
        if(botInfoObjectArr.length === 0 || botInfoObjectArr[0].botName !== botName) {
          botInfo = await queryBotByName(botName);
          fullName_ = botInfo.fullName;
          botInfoObjectArr = botInfo;  // Assuming this updates bot information in your application state
          if (!botInfo || botInfo.length === 0) {
            throw new Error("Bot information not found");
          }
        }
    
        if(isCancelled) {
          console.log('Operation cancelled after fetching bot info.');
          return;
        }
    
        const myAssistant = await openai.beta.assistants.retrieve(botInfo[0].assistantId);
        console.log('Assistant Created:', myAssistant.id);
    
        let thread;
        if(threadId == null) {
          thread = await openai.beta.threads.create();
          console.log('Thread Created:', thread.id);
          threadId = thread.id;
        }
    
        if(isCancelled) {
          console.log('Operation cancelled before sending message.');
          return;
        }
    
        await openai.beta.threads.messages.create(threadId, {
            role: "user",
            content: userInput
        });
    
        const run = await openai.beta.threads.runs.create(threadId, {
            assistant_id: myAssistant.id,
        });
    
        if(isCancelled) {
          console.log('Operation cancelled before processing run.');
          return;
        }
    
        let ragResponse = await waitForCompletion(threadId, run.id);
        console.log('ragResponse', ragResponse);
    
        if(isCancelled) {
          console.log('Operation cancelled after processing run.');
          return;
        }
    
        if(containsJson(ragResponse[0].content)) {
          let ragContent = extractTextAndJson(ragResponse[0].content);
          return [{content: ragContent.content, json: ragContent.json}];
        } else {
          return [{content: ragResponse[0].content, json: {}}];
        }
    } catch (error) {
        console.error("Error in runChatbot:", error);
        return { error: error.message };
    }
}


// window.saveData = async (obj) => {
//   try {
//     // Extract flowId and newData from the passed object
//     const { flowId } = obj;
//     let { newData } = obj; // Changed to 'let' to allow reassignment

//     const db = firebase.firestore();

//     // Query the database for the document with the matching flowId
//     const flowRef = db.collection('flows').where('flowId', '==', flowId);
//     const snapshot = await flowRef.get();

//     if (snapshot.empty) {
//       console.log('No matching documents.');
//       return;
//     }
    
//     // Reassign newData to ensure it's a simple object
//     newData = JSON.parse(JSON.stringify(newData));

//     snapshot.forEach(doc => {
//       // Update each document that matches the query
//       db.collection('flows').doc(doc.id).update({ flowData: newData });
//     });

//     console.log('Document(s) updated successfully.');
//   } catch (error) {
//     console.error('Error updating document:', error);
//   }
// }

window.addEventListener('message', (event) => {
  // Always check the origin in production!
  // if (event.origin !== 'http://example.com') return;

  if (event.data.type === 'startListening') {
    window.listenForFlowUpdates(event.data.flowId);
  }
}, false);


window.listenForFlowUpdates = (flowId) => {
  const db = firebase.firestore();
  const flowRef = db.collection('flows').where('flowId', '==', flowId);

  flowRef.onSnapshot(snapshot => {
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }

    snapshot.forEach(doc => {
      // Send updates to the iframe
      document.getElementById('undefined_output_frame').contentWindow.postMessage({
        type: 'flowUpdate',
        data: doc.data()
      }, '*'); // Replace '*' with the iframe's origin
    });
  }, error => {
    console.error('Error listening to updates:', error);
  });
}

window.isAuthenticated = () => {
  if(currentUser !== null) {
    return true;
  } else {  
    setRoomShowLoginModal(true);

  }
}

  // Function to be called from the iframe
 window.creditsNeeded = async (method) => {
      let showModalFlowId = JSON.parse(localStorage.getItem(
      `dontShowCreditModal_${EditorStore.flowId}`
    ));
    // console.log(showModalFlowId.flowId + ' ' + EditorStore.flowId)
  if(showModalFlowId == null || showModalFlowId.flowId !== EditorStore.flowId) {
  if (isLocked) {
    return new Promise((resolve, reject) => { // Wait if currently locked
      const interval = setInterval(() => {
        if (!isLocked) {
          clearInterval(interval);
          resolve(handleCreditsRequest(method));
        }
      }, 100); // Check every 100ms
    });
  } else {
    return handleCreditsRequest(method);
  }
} else {
  handleConfirm();
}
};


// Function to calculate and set total credits needed
window.calculateTotalCredits = (operations) => {
  const total = operations.reduce((sum, operation) => sum + operation.creditsNeeded, 0);
  setTotalCredits(total);
  EditorStore.setTotalCreditsNeededForFlow(total);
};



  // Confirm or cancel from modal
  const handleCreditsRequest = (method) => {
    if (userHasConfirmed) {
      return Promise.resolve(getCredits(method));
    }
    setIsLocked(true); // Lock the execution
    // let showModalFlowId = JSON.parse(localStorage.getItem(
    //   `dontShowCreditModal_${EditorStore.flowId}`
    // )).flowId;
    // if(showModalFlowId !== EditorStore.flowId) {
      setModalInfo({ isOpen: true, method });
    //}
    return new Promise((resolve, reject) => {
      setPromiseResolver({ resolve, reject });
    });
  };
// Confirm or cancel from modal
const handleConfirm = () => {
  if (FlowStore.credit <= 0) {
    closeModal();
    return; 
  }

  if (promiseResolver) {
      promiseResolver.resolve(getCredits(modalInfo.method));
  }
  setUserHasConfirmed(true); // Set that user has confirmed

  // Calculate the new credit amount and ensure it's not negative
  const newCredits = Math.max(0, FlowStore.credit - totalCredits);

  firebase
      .firestore()
      .collection("credits")
      .doc(currentUser.uid)
      .update({
          credits: newCredits,
      })
      .then(() => {
          FlowStore.setCredits(newCredits);
      })
      .catch(error => {
          console.error("Error updating credits: ", error);
      });

  closeModal();
};

  const handleCancel = () => {
    if (promiseResolver) {
      promiseResolver.reject(new Error('User cancelled the operation'));
    }
    setUserHasConfirmed(false); // Reset user confirmation if needed
    closeModal();
  };

  const closeModal = () => {
    setModalInfo({ isOpen: false, method: null });
    setIsLocked(false);
  };


  const getCredits = (methodName) => {
    // Logic to return credit value based on methodName
    switch (methodName) {
      case "chatbot":
      case "stepGPT":
      case "gpt":
      case "generateImage":
      case "customChatbot":
        return 1000;
      case "imageTo3D":
        return 5000;
      case "elevenLabs":
        return 10000;
      case "transcribeAudio":
        return 15000;
      case "tryOnClothing":
        return 15000;
      case "youtubeTranscribe":
        return 15000;  
      default:
        return 0;
    }
  };


const waitForCompletion = async (threadId, runId) => {
    while (true) {
        let runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);
        if (runStatus.status === "completed") {
            let messages = await openai.beta.threads.messages.list(threadId);
            return messages.data.map(msg => ({
                role: msg.role,
                content: msg.content[0].text.value
            }));
        } else if (runStatus.status === "failed") {
            throw new Error('Run failed with error: ' + JSON.stringify(runStatus.last_error, null, 2));
        }

        // Wait for 10 seconds before checking the status again
        await new Promise(resolve => setTimeout(resolve, 100));
    }
}


  useEffect(() => {
    // FlowStore.setCurrentRoute(props.match.params.id);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    window.addEventListener("message", (e) => {
      // if (e.origin !== "http://localhost:3000") {
      //   return;
      // }
      if (e.data.type === "custom-remix-function") {
        EditorStore.setShowRemixWithWidgetButton(true);
        if (e.data.startRemixing !== "") {
          //set custom remix for parent to call");
          WidgetStore.setStartRemixing(e.data.startRemixing);
          EditorStore.setIsRemixableByDevOnly(false);
          EditorStore.setIsRemixable(true);

        }
        if (e.data.endRemixing !== "") {
          //set custom remix for parent to call");
          WidgetStore.setEndRemixing(e.data.endRemixing);
        }
      }
      if (e.data.type === "widget") {
        WidgetStore.setShowRemixWidgetModal(true);

        // setWidgetName(e.data.widget);
        // setFunctionName(e.data.functionName);
        // setWidgetLabel(e.data.widgetLabel);
        WidgetStore.setShowWidgetByLabel(e.data.widgetName);
        if (document.getElementById("remix-widget-modal") !== null) {
          document.getElementById("remix-widget-modal").style.display = "block";
          document.getElementsByClassName(
            "remix-modal-widget-wrapper"
          )[0].style.display = "block";
        }
      }
    });
    // console.log("[Room] useEffect []");
    if (EditorStore.showAnimation === true) {
      EditorStore.showAnimationF();
    }

    if (!PublishMenuStore.isMenuAlreadySetToFalse) {
      if (!PublishMenuStore.showCreateMenu) {
        PublishMenuStore.setShowCreateMenu(false);
      }
      PublishMenuStore.setIsMenuAlreadySetToFalse(true);
    }

    let parts = window.location.pathname.split("/");
    // const flowId = parts.pop() || parts.pop(); // handle potential trailing slash
    // if (flowId && flowId !== undefined && flowId !== "" && flowId !== "new") {
    //   FlowStore.setIsLoaded(false);
    //   FlowStore.setIsLoading(true);


    //   console.log('currentUser', currentUser);

    //   firebase
    //     .firestore()
    //     .collection("flows")
    //     .doc(flowId)
    //     .get()
    //     .then((doc) => {
    //       if (!doc.exists) {
    //         return;
    //       }

          // alert(data.userId + " " + firebase.auth().currentUser.uid);

          // let data = doc.data();
          // console.log(
          //   "[Room] useEffect data:",
          //   data.userId + " " + firebase.auth().currentUser.uid
          // );

          // FlowStore.setFlowId(flowId);
          // FlowStore.setCurrentlySelectedFlowInFeed(flowId); //important for mobile (explain later)
          // FlowStore.setRoom(JSON.parse(JSON.stringify(data)));
          // if(currentUser !== null) {
          // FlowStore.setIsFlowOwner(
          //   data.userId == currentUser.uid ? true : false
          // );
          // }
          // FlowStore.setModelId(data.modelId ? data.modelId : "");
          // FlowStore.setIsLoading(false);
          // FlowStore.setIsLoaded(true);
          // FlowStore.setUserName(data.username);
          // PublishMenuStore.setAITextPopulated(data.AITextPopulated);

          // EditorStore.setIsAllDevice(data.isAllDevice);
          // EditorStore.setDeviceMobile(data.deviceMobile);
          // EditorStore.setDeviceTablet(data.deviceTablet);
          // EditorStore.setDeviceDesktop(data.deviceDesktop);
          // EditorStore.setIsNSFW(data.isNSFW);
          // EditorStore.setEnableGifRecap(data.enableGifRecap);
          // EditorStore.setEnableAIGifRecap(data.enableAIGifRecap);
          // EditorStore.setEnableSpecialShare(data.enableSpecialShare);
          // EditorStore.setScreencap(data.screencap);
          // EditorStore.setBrowserTags(
          //   data.browserTags !== undefined && data.browserTags !== null
          //     ? data.browserTags
          //     : ["chrome", "chrome", "safari", "opera", "opera"]
          // );
          // EditorStore.setPaymentPlan(data.paymentPlan);
          // EditorStore.setEditorLocked(data.editorLocked);
          // EditorStore.setIsPinned(data.isPinned);

          // if (typeof data["flowId"] === "undefined") {
          //   PublishMenuStore.setPostBtnVisible(true);
          //   PublishMenuStore.setFlowExists(false);
          //   document.getElementById("full-screen").style.display = "none";
          // } else {
          //   PublishMenuStore.setFlowExists(true);

            // function myFunction(x) {
            //   if (x.matches) {
            //     // If media query matches
            //     // document.getElementsByClassName(
            //     //   "publish-wrap-wrap"
            //     // )[0].style.height = "calc(100vh - 100px)";
            //     if (
            //       document.getElementsByClassName("block-2")[0] !== null &&
            //       document.getElementsByClassName("block-2")[0] !== undefined
            //     ) {
            //       document.getElementsByClassName("block-2")[0].style.display =
            //         "none";
            //       document.getElementsByClassName("block-3")[0].style.display =
            //         "none";
            //     }
            //   } else {
            //     // document.getElementsByClassName(
            //     //   "publish-wrap-wrap"
            //     // )[0].style.height = "calc(100vh - 71px)";

            //     if (document.getElementById("room-main-page") !== null) {
            //       // document.getElementById("remix-menu-mobile").style.display =
            //       //   "none";
            //       // document.getElementById("publish-menu-mobile").style.display =
            //       //   "none";
            //     }
            //   }
            // }

            // var x = window.matchMedia("(max-width: 768px)");
            // myFunction(x); // Call listener function at run time
            // x.addListener(myFunction); // Attach listener function on state changes

            // if (currentUser !== null) {
            //   let currentUser = currentUser.uid;
            //   if (currentUser === data.userId) {
            //     PublishMenuStore.setPostBtnVisible(false);
            //     PublishMenuStore.setSaveVisible(true);
            //   } else {
            //     PublishMenuStore.setPostBtnVisible(false);
            //     PublishMenuStore.setSaveVisible(false);
            //   }
            // }
         //}
        // })
        // .catch((error) => {
        //   console.log(error);
        // });
    //}

    incrementViews();

    // if (PublishMenuStore.flowExists === true) {
    //   if (
    //     document.getElementsByClassName("room-main-page-wrap")[0] !== null &&
    //     document.getElementsByClassName("room-main-page-wrap")[0] !== undefined
    //   ) {
    //     document.getElementsByClassName(
    //       "room-main-page-wrap"
    //     )[0].style.display = "flex";

    //     document.getElementsByClassName(
    //       "room-main-page-wrap"
    //     )[0].style.display = "flex";

    //     document.getElementsByClassName(
    //       "room-main-page-wrap"
    //     )[0].style.flex = 1;
    //     document.getElementsByClassName(
    //       "room-main-page-wrap"
    //     )[0].style.flexDirection = "row";
    //     document.getElementsByClassName(
    //       "room-main-page-wrap"
    //     )[0].style.position = "relative";
    //   }
    // }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, []);

  if (isBlocked) {
    // If the user is blocked, render this message
    return <div>The user that owns this room has blocked you. You cannot view or interact with this room unless you are unblocked.</div>;
  }

  

  // useEffect(() => {
  //   setInterval(() => {
  //     RemixableStore.setRenderHTML(true);
  //   }, 5000);
  // }, [RemixableStore.renderHTML]);
  // Function to apply styles to iframe-container


  // Function to handle window resize and apply styles based on width


  // Add event listener for window resize

  // Apply styles initially in case the page loads at <= 768px width

  return (
    <div className={styles.container}>
      <div id="room-main-page">
      {/* <Header/> */}

        {/* <div className={loading === true ? `${styles.loadingScreen}` : "none"}>
          <div className={styles.loadingContent}>
            <div className={styles.loadingImage}></div>
            <div className="loader">
              <div className="outer"></div>
              <div className="middle"></div>
              <div className="inner"></div>
            </div>
          </div>
        </div> */}
        {/* <div
          style={{
            display: WidgetStore.loadingOneMoment == true ? "flex" : "none",

            flexDirection: "column",
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            background: "#222222",
            zIndex: 100000,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 270,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className={styles.loadingText}>One Moment...</p>
            <div className="loader">
              <div className="outer"></div>
              <div className="middle"></div>
              <div className="inner"></div>
            </div>
          </div>
        </div> */}
        {(() => {
          if (PublishMenuStore.flowExists === true) {
            if (
              document.getElementsByClassName("room-main-page-wrap")[0] != null
            ) {
              document.getElementsByClassName(
                "room-main-page-wrap"
              )[0].style.display = "flex";

              document.getElementsByClassName(
                "room-main-page-wrap"
              )[0].style.flex = 1;
              document.getElementsByClassName(
                "room-main-page-wrap"
              )[0].style.flexDirection = "row";
              document.getElementsByClassName(
                "room-main-page-wrap"
              )[0].style.position = "relative";
            }
          }
        })()}
        <DeleteModal />
        <div className={`${styles.rootContainer} ${classes.root}`}>


          {/* <SetupOptions /> */}
          <SaveMessage />
          <ErrorMessage />
          {/* <PaymentOptions /> */}
        </div>
        <div className="appContainer">
        <SidebarMenu isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen} />
          <div className={`mainContent ${isPanelOpen ? 'panelOpen' : ''}`}>
          <div
              className="editor"
              style={{ width: "100%", height:'100%' }}
            >
              <Editor />
            </div>
          </div>
        </div>
        {/* <RemixModal /> */}
        <AddWidgetErrorMessage />
        <DetailsModal isPinned={EditorStore.isPinned} />
        <ShareModal />
        <NewWidgetWarningMessage />
        <CodeWarningModal />
        {/* <RemixesModal /> */}
        {/* <DetailsDescriptionModal /> */}
        <AdvancedWarningModal />

        {/* <VoiceClone /> */}
        {/* {WidgetStore.draggableWidgetVisible == true ? <DraggableWidget /> : ""} */}
      </div>
      <style>
        {`
 
.flexDisplay {
    display: flex;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Take available space */
  }

  .header {
    /* Height of the header, if fixed height is needed */
  }

  .flow {
    flex-grow: 1; /* Expand to take available space */
    overflow: hidden; /* Optional: prevent scroll within the iframe container */
  }

  .flow iframe {
    width: 100%;
    height: 100%; /* Fill the height of the parent container */
    border: none; /* Optional: to remove iframe border */
  }

  // #remix-desktop {
  //   width:378px;
  // }



  @media (max-width: 769px) {
    .parent-container {
      display: flex;
      flex-direction: row;
    }
  
    // #remix-desktop {
    //   width: 375px; /* Fixed width */
    //   /* Other styles */
    // }
  
  }
  /* New media query for mobile resolution */
  @media (max-width: 768px) {
    #remix-desktop {
      display: none;
    }


  
  }


        `}
      </style>
      {isModalOpen ? <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={500}
      // id="commentsScrollView"
      isOpen={isModalOpen}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "0px solid rgb(64, 255, 232)",
          background: "#1c1c1c",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 100000000,
          margin: "auto",
          width: "calc(100% / 1.1)",
          height: "90%",
          maxWidth: "760px",
          maxHeight: "750px",
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "20px 0px 0px 0px",
          borderBottom: "4px solid #000"
          
        }}
      >
        <img
          src="/images/comments-modal-icon.svg"
          style={{
            position: "relative",
            cursor: "pointer",
            marginLeft: 15,
            marginRight: 10,
          }}
        />

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Comments
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        <img
          onClick={() => {
            setIsModalOpen(false);
            TabStore.setClosePanel(true);
            if(document.getElementById(
              "vertical-tab-comments-tab"
            ) !== null) {
            document.getElementById(
              "vertical-tab-comments-tab"
            ).style.background = "transparent";
            document.getElementById(
              "vertical-tab-comments-tab"
            ).style.borderRadius = "0px";
            TabStore.setIsCommentsSelected(false);
            }
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer" }}
        />
      </div>

      <CommentsMobile
        style={{ position: "relative" }}
        close={() => {
          setIsModalOpen(false);
        }}
      />
    </ReactModal>:""}
    {roomShowLoginModal == true ? (<ReactModal
            isOpen={roomShowLoginModal}
            ariaHideApp={false}
            closeTimeoutMS={500}
            style={{
              overlay: {
                padding: 0,
                zIndex: 1000000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.8)",
              },
              content: {
                position: "relative",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                border: "0px solid rgb(64, 255, 232)",
                backgroundColor: "#3F3F3E",
                overflow: "hidden",
                borderRadius: "15px",
                outline: "none",
                padding: "0px",
                zIndex: 1000000,
                margin: "auto",
                width: "calc(100% / 1.1)",
                maxWidth: 570,
                // maxHeight: WidgetStore.currentModalHeight,
              },
            }}
          >
            <div
              onClick={() => {
                setRoomShowLoginModal(false);
                TabStore.setClosePanel(false);

              }}
              style={{
                position: "absolute",
                right: 0,
                margin: 10,
                cursor: "pointer",
                zIndex: 1,
              }}
            >
        
              <img src="../images/close-circle.svg"   style={{
                  display: "block",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}/>
            </div>
           <LoginM />
          </ReactModal>):""}
          <ReactModal
            isOpen={modalInfo.isOpen}
            ariaHideApp={false}
            closeTimeoutMS={500}
            style={{
              overlay: {
                padding: 0,
                zIndex: 1000000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.8)",
              },
              content: {
                position: "relative",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                border: "0px solid rgb(64, 255, 232)",
                backgroundColor: "#3F3F3E",
                overflow: "hidden",
                borderRadius: "15px",
                outline: "none",
                padding: "0px",
                zIndex: 1000000,
                margin: "auto",
                width: "calc(100% / 1.1)",
                maxWidth: 370,
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
                // maxHeight: WidgetStore.currentModalHeight,
              },
            }}
          >
                  <div
        style={{
          height: 52,
          width: "100%",
          background: "#1C1C1C",
          display: "flex",
          alignItems: "center",

          borderRadius: "15px 0px 0px 0px",
          borderBottom: "4px solid #000",
        }}
      >
               <img src="/images/share-icon-modal.svg"  style={{marginRight:10, marginLeft:20}}/>

        <p
          style={{
            color: "#222222",
            fontSize: 18,
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Credits needed!
        </p>
        {/* <FontAwesomeIcon
          onClick={() => {
            props.close();
          }}
          icon={faTimesCircle}
          color="#222222"
          style={{
            display: "block",
            position: "absolute",
            right: 10,
            fontSize: 35,
            cursor: "pointer",
          }}
        /> */}
        
        <img
          onClick={() => {
            setModalInfo({ isOpen: false });
          }}
          src="/images/close-circle.svg"
          style={{ position: "absolute", right: 25, cursor: "pointer",  }}
        />
      </div>
       
          <div style={{   
    right: 0,
    margin: 0,
    cursor: 'pointer',
    /* z-index: 1; */
    width: '100%',
      display:"flex",
      backgroundColor: "#1C1C1C",
      borderRadius: 0,
      zIndex: 2,
      position: "relative",
    flexDirection:'column',
    borderTop:'1px solid #323232',
    justifyContent:'center',
    alignItems:'center',
    padding:30
}}>
  <p style={{color:'white', fontFamily:'quicksand', marginTop:0, marginBottom:20}}>This requires credits to use.</p>
  {FlowStore.credit <= 0 ? (<p style={{color:'white', fontFamily:'quicksand', marginRight:'auto', marginLeft:'auto', marginBottom:10, fontSize:14}}>IMPORTANT: You have must get more credits to continue!</p>):""}
  {!FlowStore.credit <= 0 ? (<p style={{color:'white', fontFamily:'quicksand', marginRight:'auto', marginLeft:'auto', marginBottom:10, fontSize:14}}>You have {`${FlowStore.credit} credits`}</p>):""}
  {FlowStore.credit <= 0 ? (<p style={{color:'white', fontFamily:'quicksand', marginRight:'auto', marginLeft:'auto', marginBottom:10, fontSize:14, marginBottom:40}}>This app will not work properly without the credits needed!</p>):""}
  {!FlowStore.credit <= 0 ? (<p style={{color:'white', fontFamily:'quicksand', marginRight:'auto', marginLeft:'auto', marginBottom:35, fontSize:14}}>{`${totalCredits} credits will be deducted.`}</p>):""}
  <div style={{display:'flex', alignItems:'center', position: 'absolute',
    left: 20,
    marginTop: 10,
    marginBottom: 10, 
    marginLeft:14
    }}>
  <Checkbox
            checked={dontShow}
            icon={
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderWidth: 8,
                  borderColor:'rgb(79, 217, 117)',
                  borderStyle: 'solid',
                  borderRadius: 3,
                  marginBottom: 1,
                  marginRight: 12,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 15
                }}
              />
            }
            onChange={(e) => {

              if (e === true) {
                localStorage.setItem(
                  `dontShowCreditModal_${EditorStore.flowId}`,
                  JSON.stringify({ flowId: EditorStore.flowId })
                );
                setDontShow(e);
              }
            }}
            // inputProps={{ "aria-label": "primary checkbox" }}
            style={{
              height: 20,
              backgroundColor: "rgb(88, 83, 85)",
              marginBottom: 0,
              marginRight: 10,
            }}
            borderColor={"#2d2a2b"}
            borderRadius={5}
            size={20}
          />
          <p style={{fontFamily:'quicksand', fontSize:11, color:'white'}}>Don't show this again</p>
          </div>
          <p style={{

fontFamily: 'quicksand',
fontSize: 11,
marginBottom: 10,
marginTop: 5,
color: '#F06263',
marginTop: 28,
marginLeft: 5

          }}>WARNING: You will not be notified when you use credits again</p>
  <div style={{backgroundColor:'#4FD975', maxWidth:300, display:'flex', justifyContent:'center', alignItems:'center', width:'100%', borderRadius:7, height: 30, marginBottom:15}} onClick={()=>{

   handleConfirm()
  }}><p style={{fontFamily:'quicksand',color:'#1c1c1c'}}>ok</p></div>
    <div onClick={()=>{
    setUserDecision(false);
    setShowCreditModal(false);
    setModalInfo({ isOpen: false });
  }} style={{border:'1px solid #4FD975', maxWidth:300, display:'flex', justifyContent:'center', alignItems:'center', width:'100%', borderRadius:7, height: 30}}><p style={{color:'#4FD975', fontFamily:'quicksand'}}>Cancel</p></div>
  
</div>
          </ReactModal>
          
    </div>
  );
});

export default React.memo(Room);
