import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { FlowStoreContext } from "../../store/FlowStore.js";

import { firebase } from "../firebase/firebase";
import { AuthContext } from "./AuthContext";
import { RoomContext } from "./RoomContext.js";
import { useNavigate, useParams } from "react-router-dom"; // Updated import
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";

const CollaboratorList = observer(({ flowId, userId }) => {
  const [collaborators, setCollaborators] = useState([]);
  const [collaborator, setCollaborator] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [yourRole, setYourRole] = useState("");
  const [yourRoleError, setYourRoleError] = useState("");
  const [collaboratorRoleError, setCollaboratorRoleError] = useState("");
  const [collaboratorRole, setCollaboratorRole] = useState("");
  const [isOwnerRemovable, setIsOwnerRemovable] = useState(false);

  const FlowStore = useContext(FlowStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const AuthenticationStore = useContext(AuthenticationStoreContext);

  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false); // New state for accordion
  const [isPromoOpen, setIsPromoOpen] = useState(false);
  const firestore = firebase.firestore();
  const { roomId, commentId, replyId } = useParams(); // Extract both roomId and commentId

  const room = useContext(RoomContext);



  const fetchCollaborators = async (flowId) => {
    const db = firebase.firestore();
    try {
      const querySnapshot = await db.collection("notifications")
        .where("flowId", "==", flowId)
        .where("type", "==", "collabRequest")
        .get();

      const fetchedCollaborators = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          username: data.username,
          status: data.isAccepted ? "" : "pending",
          collaboratorRole:data.collaboratorRole
        };
      });

      console.log('fetched Collaborators', fetchedCollaborators)

      setCollaborators([...fetchedCollaborators]);
    } catch (error) {
      console.error("Error fetching collaborators: ", error);
    }
  };

  const addLikeNotification = async (sender, receiver, commentContent, photoURL, collaboratorRole, ownerRole, username) => {
    const db = firebase.firestore();
    const flowId = EditorStore.flowId || "";
   setErrorMessage("");
    try {
        const querySnapshot = await db.collection("notifications")
            .where("receiver", "==", receiver)
            .where("username", "==", username)
            .where("type", "==", "collabRequest")
            .where("flowId", "==", flowId)
            .get();

        if (!querySnapshot.empty) {
            console.error("This user has already been sent a collaboration request.");
            setErrorMessage("This user has already been sent a collaboration request.");
            return;
        }

        const newNotification = {
            sender,
            receiver,
            type: "collabRequest",
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            isAccepted: false,
            flowId,
            additionalData: { commentContent, photoURL },
            collaboratorRole,
            ownerRole,
            username
        };


        if (currentUser.displayName == username) {
            const selfNotification = {
                sender: EditorStore.username,
                receiver: EditorStore.username,
                type: "collabRequest",
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                isAccepted: true,
                flowId,
                additionalData: { commentContent: "", photoURL: "" },
                collaboratorRole: collaboratorRole,
                ownerRole: true,
                username: EditorStore.username
            };

            await db.collection("notifications").add(selfNotification);
            setCollaborators([selfNotification]); // Set owner first when list is empty
            setIsOwnerRemovable(true); // Initially, owner cannot remove themselves
        } else {
        await db.collection("notifications").add(newNotification);

        setCollaborators(prev => {
            const ownerIndex = prev.findIndex(collab => collab.ownerRole);
            //if (ownerIndex > -1) {
                const newCollabs = [...prev];
                const ownerData = newCollabs.splice(ownerIndex, 1)[0];
                return [ownerData, ...newCollabs, newNotification];
            //}
            return [...prev, newNotification];
        });

        console.log("Collaboration request sent successfully.");
        document.getElementById('collaborator').value = '';
        document.getElementById('collaboratorRole').value = '';
        document.getElementById('your-role').value = '';
      }
        
    } catch (error) {
        console.error("Error sending collaboration request: ", error);
    }
};




  


  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        // Fetch users who accepted the collaboration request
        const acceptedSnapshot = await firestore
          .collection("notifications")
          .where("additionalData.flowId", "==", flowId)
          .where("type", "==", "collabAccepted")
          .where("receiver", "==", userId)
          .get();
        console.log("acceptedSnapshot", acceptedSnapshot);

        const acceptedCollaborators = acceptedSnapshot.docs.map((doc) => ({
          id: doc.data().sender,
          status: "accepted",
          docId: doc.id,
        }));
        console.log("acceptedCollaborators", acceptedCollaborators);

        // Fetch users who have been sent a collaboration request but haven't accepted yet
        const pendingSnapshot = await firestore
          .collection("notifications")
          .where("additionalData.flowId", "==", flowId)
          .where("type", "==", "collabRequestSent")
          .where("sender", "==", userId)
          .get();

        const pendingCollaborators = pendingSnapshot.docs.map((doc) => ({
          id: doc.data().receiver,
          status: "pending",
          docId: doc.id,
        }));

        // Merge both lists and update state
        const combinedCollaborators = [
          ...pendingCollaborators,
          ...acceptedCollaborators,
        ];

        console.log(acceptedSnapshot.docs);
        console.log(pendingSnapshot.docs);
        setCollaborators(combinedCollaborators);
      } catch (error) {
        console.error("Error fetching collaborators:", error);
      }
    };

    fetchCollaborators();
    setYourRole(EditorStore.yourRole);
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
  }, [userId, flowId]);

  const uniqueCollaborators = collaborators
    .filter((collab) => collab.id !== userId)
    .reduce(
      (acc, collab) => {
        if (!acc.ids.has(collab.id)) {
          acc.ids.add(collab.id);
          acc.result.push(collab);
        }
        return acc;
      },
      { ids: new Set(), result: [] }
    ).result;



    const addYourselfCollaborator = async (currentUser, room) => {
      if (!currentUser) {
        console.log("User not logged in");
        return;
      }
      // const currentTime = Date.now();
      // if (currentTime - lastClickTime < 1000) {
      //   console.log("Action too fast. Try again later.");
      //   return;
      // }
      // setLastClickTime(currentTime);
      
      const collaboratorRef = firebase.firestore().collection("collaborators").doc();
    
      collaboratorRef.set({
        username: currentUser,
        flowId: room,
        timestamp: new Date().toISOString()
      })
      .then(() => {
        console.log("Collaborator added successfully");
      })
      .catch((error) => {
        console.error("Error adding collaborator: ", error);
      });
    };
    
    


  const addCollaborator = async (collabId, collaboratorRole, yourRole) => {
    // if (
    //   collabId === userId ||
    //   collaborators.some((collab) => collab.id === collabId)
    // ) {
    //   setErrorMessage(
    //     "Your username will automatically be added as a collaborator once you add a collaborator other than yourself."
    //   );
    //   return;
    // }

    // Check if the username exists in the users collection
    const userSnapshot = await firestore
      .collection("users")
      .where("username", "==", collabId)
      .get();

    if (collaborator.trim() == "") {
      setErrorMessage("You must enter a username");
      return;
    }

    if (userSnapshot.empty) {
      setErrorMessage("This user doesn't exist.");
      return;
    }

    await addLikeNotification(
      currentUser.displayName,
      collaborator,
      "",
      AuthenticationStore.photoURL,
      collaboratorRole, 
      yourRole,
      collabId
    );
    // addYourselfCollaborator(currentUser.displayName, EditorStore.flowId);
    // const collabData = { id: collabId, status: "pending" };
    // setCollaborators((prevCollabs) => [...prevCollabs, collabData]);

    // setCollaborator("");
    // setCollaboratorRole("");
  };
  

 


  useEffect(() => {
    if (EditorStore.flowId) {
      // fetchCollaborators(EditorStore.flowId);
    }
  }, [EditorStore.flowId]);

  const removeCollaborator = async (receiver, username) => {
    const db = firebase.firestore();
    setErrorMessage("");
    try {
        // if (username === EditorStore.username && collaborators.length > 1) {
        //     setErrorMessage("You cannot remove yourself unless you remove all other collaborators first.");
        //     return;
        // }

        const querySnapshot = await db.collection("notifications")
            .where("username", "==", username)
            .where("type", "==", "collabRequest")
            .get();

        if (querySnapshot.empty) {
            console.log("No documents found to delete.");
            return;
        }

        const batch = db.batch();
        querySnapshot.docs.forEach(doc => batch.delete(doc.ref));

        await batch.commit();
        console.log("All matching documents successfully deleted!");

        fetchCollaborators(EditorStore.flowId);

        setCollaborators(prev => {
            const newCollabs = prev.filter(collab => collab.username !== username || collab.receiver !== receiver);
            if (username === EditorStore.username) {
                setIsOwnerRemovable(newCollabs.length === 0);
            }
            return newCollabs;
        });
    } catch (error) {
        console.error("Error removing documents: ", error);
        setErrorMessage("Failed to delete collaborator from the database.");
    }
};





  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p
            style={{
              fontFamily: "quicksand",
              fontSize: 14,
              color: "#4FD975",
              marginLeft: 0,
              marginTop:12
            }}
          >
            Collaborator Settings
          </p>
          <img src="../images/collaborator-down-arrow.svg" style={{marginLeft:10, marginBottom:0, marginTop:12}}/>
        </div>
        {isOpen && (
          <>
            <p
              style={{
                fontFamily: "quicksand",
                fontSize: 12,
                color: "white",
                marginLeft: 0,
                marginTop: 12,
                fontWeight: "bolder",
                maxWidth:455,
                width:'100%'
              }}
            >
              If you are adding a collaborator, you must initially type your role i.e. "developer", "javascript", "idea person" or however you want to describe your role, then type the username of a collaborator and their role then select "Add Collaborator". You only have to add your role initially, not each time you add a new collaborator. You do not need to enter your username, it will be added automatically.
            </p>
            {isOwnerRemovable  == false ? (<div
              style={{
                display: "flex",
                margin: "auto",
                width: "100%",
              }}
            >
              <input
                maxLength="10"
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // If the user tries to only input spaces (but not if the field is completely empty)
                  if (inputValue && inputValue.trim() === "") {
                    setYourRoleError("Entry cannot be just spaces!");
                  }
                  // Check if the input value length has reached the max length
                  else if (inputValue.length >= e.target.maxLength) {
                    setYourRoleError("Maximum character limit reached!");
                  }
                  // If neither of the above conditions are met, clear the error message
                  else {
                    setYourRoleError("");
                  }

                  setYourRole(inputValue);
                  EditorStore.setYourRole(inputValue);
                }}
                id="your-role"
                type="text"
                style={{
                  height: 38,
                  width: "100%",
                  backgroundColor: "#2C2C2C",
                  border: "none",
                  borderRadius: 10,
                  paddingLeft: 15,
                  color: "white",
                  outline: "none",
                  fontFamily: "quicksand",
                  fontSize: 11,
                  marginTop:10,
                  marginRight:10,

                }}
                placeholder="Type your role here"
                value={yourRole}
              />

              <div
                onClick={() => {
                  setYourRole("");
                  setErrorMessage("");
                }}
                style={{
                  height: 37,
                  width: 82,
                  background: "rgb(79, 217, 117)",
                  fontFamily: "Quicksand",
                  fontSize: 10,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop:10 
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 11
                  }}
                >
                  Clear
                </p>
              </div>
            </div>):""}
            <p style={{ color: "#FF5C5C", fontSize: 14, marginTop:10, fontFamily:'quicksand' }}>{yourRoleError}</p>
            <div
              style={{
                display: "flex",
                margin: "auto",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                marginTop: 12,
              }}
            >
              <div style={{ display: "flex", width:'calc(100% - 170px)' }}>
                <input
                  maxLength="15"
                  id="collaborator"
                  onChange={(e) => {
                    const inputValue = e.target.value.toLowerCase(); // Convert input to lowercase
                    const regex = /^[a-z0-9 ]*$/; // Allow only lowercase alphanumeric characters and space
                    setErrorMessage("");

                    // If the user tries to only input spaces (but not if the field is completely empty)
                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    if (!regex.test(inputValue)) {
                      setCollaboratorRoleError(
                        "Special characters like #$@ are not allowed or uppercase letters!"
                      );
                      return;
                    }

                    setCollaborator(inputValue); // Save the lowercase value to state

                    // Check if the input value length has reached the max length
                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for collaborator!"
                      );
                    }
                  }}
                  type="text"
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    marginRight: 20,
                    fontSize: 12,
                    textTransform: "lowercase",
                  }}
                  placeholder="Username"
                  value={collaborator}
                />

                <input
                  id="collaboratorRole"
                  maxLength="10"
                  type="text"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setYourRoleError("");

                    // If the user tries to only input spaces (but not if the field is completely empty)
                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    // Set the value to the state
                    setCollaboratorRole(inputValue);

                    // Check if the input value length has reached the max length
                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for role!"
                      );
                    }
                  }}
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    fontSize: 12,
                  }}
                  placeholder="Role"
                  value={collaboratorRole} // ensure you're setting the value here
                />
              </div>
              <div
                id="add-collaborator-btn"
                style={{
                  backgroundImage: `url(../images/add-collaborator.svg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "154px",
                  height: 38,
                  width: 155,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCollaboratorRoleError("");
                  // Check for empty fields or fields with only spaces
                  if (!collaborator || collaborator.trim() === "") {
                    setErrorMessage("You must enter a username");
                    return; // Exit and don't continue
                  }

                  // if (!yourRole || yourRole.trim() === "") {
                  //   setYourRoleError("You must add your own role but do NOT need to add your own username. Just enter your role as well as the username for the collaborator other than yourself. Your username will be added automatically. You do not have to add your role again after adding it initially.");
                  //   return; // Exit and don't continue
                  // }

                  if (!collaboratorRole || collaboratorRole.trim() === "") {
                    setCollaboratorRoleError(
                      "Role cannot be empty or just spaces!"
                    );
                    return; // Exit and don't continue
                  }

                  if (uniqueCollaborators.length > 50) {
                    setCollaboratorRoleError(
                      "You can not have more than 50 collaborators"
                    );
                    return;
                  }
              
                  //If all validations passed, add the collaborator
                  addCollaborator(
                    collaborator.trim(),
                    collaboratorRole.trim(),
                    yourRole.trim()
                  );
                }}
              ></div>
            </div>
            <p style={{ color: "#FF5C5C", fontSize: 14, fontFamily:'quicksand', marginTop:10 }}>
              {collaboratorRoleError}
            </p>

            <p
              id="error-message"
              style={{ color: "#FF5C5C", fontFamily: "quicksand", fontSize: 14, marginTop:10 }}
            >
              {errorMessage}
            </p>
            <ul
              style={{
                height: 70,
                width: "100%",
                overflowY: "scroll",
                backgroundColor: "rgb(44, 44, 44)",
                borderRadius: 10,
                padding: 10,
                color: "white",
                listStyle: "none",
                fontFamily: "quicksand",
                fontSize: 14,
                marginTop: 10
                }}>
                {collaborators.length === 0 ? (
                  <p style={{ fontFamily: "quicksand", fontSize: 12 }}>
                     No added collaborators
                  </p>
                ) : (
                collaborators.map(collab => (
                  <li
                    key={collab.id}
                    style={{ display: "flex", justifyContent: "space-between" }}>
                    {collab.username}
                    {collab.ownerRole && collab.username === EditorStore.username ? (
                    <span style={{color:'white', fontFamily:'quicksand', fontSize:12}}>Owner</span>) : (
                      collab.status === "pending" && <span> - Pending</span>
                    )}
                    <span>{collab.collaboratorRole}</span>
                    <button 
                      onClick={() => removeCollaborator(collab.id, collab.username)} 
                      style={{
                        height: 20,
                        width: 55,
                        background: 'rgb(79, 217, 117)',
                        fontFamily: 'Quicksand',
                        fontSize: 10,
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        border: 'none',
                        fontWeight: 'bold',
                        marginBottom: 10
                    }}>
                      Delete
                    </button>
                </li>
              ))
            )}
          </ul>

          </>
        )}
              {/* <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => setIsPromoOpen(!isPromoOpen)}
        >
          <p
            style={{
              fontFamily: "quicksand",
              fontSize: 14,
              color: "#4FD975",
              marginLeft: 0,
              marginTop:12
            }}
          >
            Make A Promo Request 
          </p>
          <img src="../images/collaborator-down-arrow.svg" style={{marginLeft:10, marginBottom:0, marginTop:12}}/>
        </div> */}
        {isPromoOpen && (
          <>
            <p
              style={{
                fontFamily: "quicksand",
                fontSize: 12,
                color: "white",
                marginLeft: 0,
                marginTop: 5,
                fontWeight: "bolder",
                maxWidth:455,
                width:'100%'
              }}
            >
              If you are adding a collaborator, you must initially type your role i.e. "developer", "javascript", "idea person" or however you want to describe your role, then type the username of a collaborator and their role then select "Add Collaborator". You only have to add your role initially, not each time you add a new collaborator. You do not need to enter your username, it will be added automatically.
            </p>
            {isOwnerRemovable  == false ? (<div
              style={{
                display: "flex",
                margin: "auto",
                width: "100%",
              }}
            >
              <input
                maxLength="10"
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // If the user tries to only input spaces (but not if the field is completely empty)
                  if (inputValue && inputValue.trim() === "") {
                    setYourRoleError("Entry cannot be just spaces!");
                  }
                  // Check if the input value length has reached the max length
                  else if (inputValue.length >= e.target.maxLength) {
                    setYourRoleError("Maximum character limit reached!");
                  }
                  // If neither of the above conditions are met, clear the error message
                  else {
                    setYourRoleError("");
                  }

                  setYourRole(inputValue);
                  EditorStore.setYourRole(inputValue);
                }}
                id="your-role"
                type="text"
                style={{
                  height: 38,
                  width: "100%",
                  backgroundColor: "#2C2C2C",
                  border: "none",
                  borderRadius: 10,
                  paddingLeft: 15,
                  color: "white",
                  outline: "none",
                  fontFamily: "quicksand",
                  fontSize: 11,
                  marginTop:10,
                  marginRight:10,

                }}
                placeholder="Type your role here"
                value={yourRole}
              />

              <div
                onClick={() => {
                  setYourRole("");
                  setErrorMessage("");
                }}
                style={{
                  height: 37,
                  width: 82,
                  background: "rgb(79, 217, 117)",
                  fontFamily: "Quicksand",
                  fontSize: 10,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop:10 
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 11
                  }}
                >
                  Clear
                </p>
              </div>
            </div>):""}
            <p style={{ color: "#FF5C5C", fontSize: 14, marginTop:10, fontFamily:'quicksand' }}>{yourRoleError}</p>
            <div
              style={{
                display: "flex",
                margin: "auto",
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                marginTop: 12,
              }}
            >
              <div style={{ display: "flex", width:'calc(100% - 170px)' }}>
                <input
                  maxLength="15"
                  id="collaborator"
                  onChange={(e) => {
                    const inputValue = e.target.value.toLowerCase(); // Convert input to lowercase
                    const regex = /^[a-z0-9 ]*$/; // Allow only lowercase alphanumeric characters and space
                    setErrorMessage("");

                    // If the user tries to only input spaces (but not if the field is completely empty)
                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    if (!regex.test(inputValue)) {
                      setCollaboratorRoleError(
                        "Special characters like #$@ are not allowed or uppercase letters!"
                      );
                      return;
                    }

                    setCollaborator(inputValue); // Save the lowercase value to state

                    // Check if the input value length has reached the max length
                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for collaborator!"
                      );
                    }
                  }}
                  type="text"
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    marginRight: 20,
                    fontSize: 12,
                    textTransform: "lowercase",
                  }}
                  placeholder="Username"
                  value={collaborator}
                />

                <input
                  id="collaboratorRole"
                  maxLength="10"
                  type="text"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setYourRoleError("");

                    // If the user tries to only input spaces (but not if the field is completely empty)
                    if (inputValue && inputValue.trim() === "") {
                      setCollaboratorRoleError("Entry cannot be just spaces!");
                      return;
                    }

                    // Set the value to the state
                    setCollaboratorRole(inputValue);

                    // Check if the input value length has reached the max length
                    if (inputValue.length >= e.target.maxLength) {
                      setCollaboratorRoleError(
                        "Maximum character limit reached for role!"
                      );
                    }
                  }}
                  style={{
                    height: 38,
                    width: "100%",
                    backgroundColor: "#2C2C2C",
                    border: "none",
                    borderRadius: 10,
                    paddingLeft: 10,
                    color: "white",
                    outline: "none",
                    maxWidth: 300,
                    fontFamily: "quicksand",
                    fontSize: 12,
                  }}
                  placeholder="Role"
                  value={collaboratorRole} // ensure you're setting the value here
                />
              </div>
              <div
                id="add-collaborator-btn"
                style={{
                  backgroundImage: `url(../images/add-collaborator.svg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "154px",
                  height: 38,
                  width: 155,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCollaboratorRoleError("");
                  // Check for empty fields or fields with only spaces
                  if (!collaborator || collaborator.trim() === "") {
                    setErrorMessage("You must enter a username");
                    return; // Exit and don't continue
                  }

                  // if (!yourRole || yourRole.trim() === "") {
                  //   setYourRoleError("You must add your own role but do NOT need to add your own username. Just enter your role as well as the username for the collaborator other than yourself. Your username will be added automatically. You do not have to add your role again after adding it initially.");
                  //   return; // Exit and don't continue
                  // }

                  if (!collaboratorRole || collaboratorRole.trim() === "") {
                    setCollaboratorRoleError(
                      "Role cannot be empty or just spaces!"
                    );
                    return; // Exit and don't continue
                  }

                  if (uniqueCollaborators.length > 50) {
                    setCollaboratorRoleError(
                      "You can not have more than 50 collaborators"
                    );
                    return;
                  }
              
                  //If all validations passed, add the collaborator
                  addCollaborator(
                    collaborator.trim(),
                    collaboratorRole.trim(),
                    yourRole.trim()
                  );
                }}
              ></div>
            </div>
            <p style={{ color: "#FF5C5C", fontSize: 14, fontFamily:'quicksand', marginTop:10 }}>
              {collaboratorRoleError}
            </p>

            <p
              id="error-message"
              style={{ color: "#FF5C5C", fontFamily: "quicksand", fontSize: 14, marginTop:10 }}
            >
              {errorMessage}
            </p>
            <ul
              style={{
                height: 70,
                width: "100%",
                overflowY: "scroll",
                backgroundColor: "rgb(44, 44, 44)",
                borderRadius: 10,
                padding: 10,
                color: "white",
                listStyle: "none",
                fontFamily: "quicksand",
                fontSize: 14,
                marginTop: 10
                }}>
                {collaborators.length === 0 ? (
                  <p style={{ fontFamily: "quicksand", fontSize: 12 }}>
                     No added collaborators
                  </p>
                ) : (
                collaborators.map(collab => (
                  <li
                    key={collab.id}
                    style={{ display: "flex", justifyContent: "space-between" }}>
                    {collab.username}
                    {collab.ownerRole && collab.username === EditorStore.username ? (
                    <span style={{color:'white', fontFamily:'quicksand', fontSize:12}}>Owner</span>) : (
                      collab.status === "pending" && <span> - Pending</span>
                    )}
                    <span>{collab.collaboratorRole}</span>
                    <button 
                      onClick={() => removeCollaborator(collab.id, collab.username)} 
                      style={{
                        height: 20,
                        width: 55,
                        background: 'rgb(79, 217, 117)',
                        fontFamily: 'Quicksand',
                        fontSize: 10,
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        border: 'none',
                        fontWeight: 'bold',
                        marginBottom: 10
                    }}>
                      Delete
                    </button>
                </li>
              ))
            )}
          </ul>

          </>
        )}
      </div>
    </div>
  );
});

export default CollaboratorList;
